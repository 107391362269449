<template>
  <div class="w-full mx-0 px-10 pt-5">
    <a href="#" @click="$router.go(-1)"> &lt Aerobox</a>
    <div class="flex p-5">
      <div class="w-96 text-xl">Aerobox： {{ prop.id.toUpperCase() }}</div>
      <div class="flex items-center">
        <div class="tabs">
          <button value="today" :class="['tab', 'btn', { 'tab-active': time_type === 'today' }]"
            @click="setCurrentTab('today')" :disabled="isLoading">Today</button>
          <button value="yesterday" :class="['tab', 'btn', { 'tab-active': time_type === 'yesterday' }]"
            @click="setCurrentTab('yesterday')" :disabled="isLoading">Yesterday</button>
          <button value="week" :class="['tab', 'btn', { 'tab-active': time_type === 'week' }]"
            @click="setCurrentTab('week')" :disabled="isLoading">This week</button>
          <button value="month" :class="['tab', 'btn', { 'tab-active': time_type === 'month' }]"
            @click="setCurrentTab('month')" :disabled="isLoading">This month</button>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="flex justify-center pt-40">
      <span class="loading loading-spinner loading-lg "></span>
    </div>

    <div v-else class="chart_group">
      <div class="flex flex-col md:flex-row">
        <div class="w-full md:w-1/2 " style="height:50vh">
          <VuePlotly :data="plot_pm25" :layout="pm2_5" :display-mode-bar="false"></VuePlotly>
        </div>
        <div class="w-full md:w-1/2" style="height:50vh">
          <VuePlotly :data="plot_co2" :layout="co2" :display-mode-bar="false"></VuePlotly>
        </div>
      </div>
      <div class="flex flex-col md:flex-row">
        <div class="w-full md:w-1/2" style="height:50vh">
          <VuePlotly :data="plot_rh" :layout="rh" :display-mode-bar="false"></VuePlotly>
        </div>
        <div class="w-full md:w-1/2" style="height:50vh">
          <VuePlotly :data="plot_temp" :layout="temp" :display-mode-bar="false"></VuePlotly>
        </div>
      </div>
      <div class="flex flex-col md:flex-row">
        <div class="w-full md:w-1/2" style="height:50vh">
          <VuePlotly :data="plot_pm10" :layout="pm10" :display-mode-bar="false"></VuePlotly>
        </div>
        <div class="w-full md:w-1/2" style="height:50vh">
          <VuePlotly :data="plot_pr" :layout="pr" :display-mode-bar="false"></VuePlotly>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { VuePlotly } from 'vue3-plotly'
import { useRoute } from 'vue-router';
import { defineProps } from 'vue';
const prop = defineProps(['id']);

const time_type = ref('today')

const isLoading = ref(true)

function setCurrentTab(type) {
  isLoading.value = true

  if (type === 'today') {
    getPlot()
  } else if (type === 'yesterday') {
    const todayy = new Date();
    const todayUTC = new Date(Date.UTC(todayy.getUTCFullYear(), todayy.getUTCMonth(), todayy.getUTCDate()))

    const yesterdayy = new Date(todayUTC);
    yesterdayy.setUTCDate(yesterdayy.getUTCDate() - 1);

    // 获取昨天的开始时间和结束时间（UTC时间）
    const yesterdayStartUTC = new Date(yesterdayy);
    yesterdayStartUTC.setUTCHours(0, 0, 0, 0);

    const yesterdayEndUTC = new Date(yesterdayy);
    yesterdayEndUTC.setUTCHours(23, 59, 59, 999);

    getPlot(10000, yesterdayEndUTC.getTime() * 1000, yesterdayStartUTC.getTime() * 1000)
  } else if (type === 'week') {
    const nowUTC = new Date();

    // 获取本周的开始时间（UTC时间）
    const thisWeekStartUTC = new Date(Date.UTC(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate() - nowUTC.getUTCDay(), 0, 0, 0));
    getPlot(100000, null, thisWeekStartUTC.getTime() * 1000)

  } else {
 
    const nowUTC = new Date();
    // 获取本月的开始时间（UTC时间）
    const thisMonthStartUTC = new Date(Date.UTC(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), 1));
    getPlot(3000000, null, thisMonthStartUTC.getTime() * 1000)
  }


  time_type.value = type
}

const router = useRoute()

const pm2_5 = {
  title: "PM2.5",
  xaxis: {
    title: "Time (UTC)"
  },
  yaxis: {
    title: "PM2.5(μg／m^3)"
  }
}
const co2 = {
  title: "CO2",
  xaxis: {
    title: "Time (UTC)"
  },
  yaxis: {
    title: "CO2(ppm)"
  }
}
const rh = {
  title: "RH",
  xaxis: {
    title: "Time (UTC)"
  },
  yaxis: {
    title: "RH(%)"
  }
}
const temp = {
  title: "Temperature",
  xaxis: {
    title: "Time (UTC)"
  },
  yaxis: {
    title: "Temp(°C)"
  }
}
const pm10 = {
  title: "PM10",
  xaxis: {
    title: "Time (UTC)"
  },
  yaxis: {
    title: "PM10(μg／m^3)"
  }
}
const pr = {
  title: "Pressure",
  xaxis: {
    title: "Time (UTC)"
  },
  yaxis: {
    title: "hPa"
  }
}

const plot_data = ref([])

const plot_pm25 = ref([])
const plot_co2 = ref([])
const plot_rh = ref([])
const plot_temp = ref([])
const plot_pm10 = ref([])
const plot_pr = ref([])

function getPlot(limit, before, after) {

  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}list/aerobox/plot`, { deviceId: prop.id, limit, before, after }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      console.log(data.data)
      plot_data.value = data.data.payload
      const pm25 = []
      const co2 = []
      const rh = []
      const pm10 = []
      const pr = []
      const temp = []

      const Time = []
      if (data.data.payload) {
        data.data.payload.map(each => {
          pm25.push(each.Payload.Pm2_5)
          co2.push(each.Payload.Co2)
          pm10.push(each.Payload.Pm10)
          rh.push(each.Payload.Rh)
          temp.push(each.Payload.Temp)
          pr.push(each.Payload.Pressure)

          Time.push(each.DeviceTimestamp.toLocaleString())
        })
      }

      console.log(pm25)
      plot_pm25.value = [{ x: Time, y: pm25, type: "scatter", mode: "lines+markers", marker: { color: "#993300", size: 8 }, line: { width: 4 } }]
      plot_pm10.value = [{ x: Time, y: pm10, type: "scatter", mode: "lines+markers", marker: { color: "black", size: 8 }, line: { width: 4 } }]
      plot_co2.value = [{ x: Time, y: co2, type: "scatter", mode: "lines+markers", marker: { color: "#ff0066", size: 8 }, line: { width: 4 } }]
      plot_rh.value = [{ x: Time, y: rh, type: "scatter", mode: "lines+markers", marker: { color: "#0033cc", size: 8 }, line: { width: 4 } }]
      plot_temp.value = [{ x: Time, y: temp, type: "scatter", mode: "lines+markers", marker: { color: "#ff6600", size: 8 }, line: { width: 4 } }]
      plot_pr.value = [{ x: Time, y: pr, type: "scatter", mode: "lines+markers", marker: { color: "pink", size: 8 }, line: { width: 4 } }]

    }).then(result => {
      isLoading.value = false
      console.log('success')
    }
    ).catch(err => console.log(err))

}

getPlot()

//   mounted: function () {
//     Plotly.newPlot(
//       "chart1",
//       [
//         {
//           x: [],
//           y: [],
//           mode: "lines+markers",
//           marker: { color: "#993300", size: 8 },
//           line: { width: 4 },
//           type: "scattergl",
//         },
//       ],
//       {
//         title: {
//           text: "PM2.5",
//         },
//         xaxis: {
//           title: {
//             text: "Time(UTC)",
//           },
//         },
//         yaxis: {
//           title: {
//             text: "PM2.5(μg／m^3)",
//           },
//         },
//       },
//       {}
//     );

//     Plotly.newPlot(
//       "chart2",
//       [
//         {
//           x: [],
//           y: [],
//           mode: "lines+markers",
//           marker: { color: "#0033cc", size: 8 },
//           line: { width: 4 },
//           type: "scattergl",
//         },
//       ],
//       {
//         title: {
//           text: "RH",
//         },
//         xaxis: {
//           title: {
//             text: "Time(UTC)",
//           },
//         },
//         yaxis: {
//           title: {
//             text: "RH(%)",
//           },
//         },
//       },
//       {}
//     );

//     Plotly.newPlot(
//       "chart3",
//       [
//         {
//           x: [],
//           y: [],
//           mode: "lines+markers",
//           marker: { color: "#ff6600", size: 8 },
//           line: { width: 4 },
//           type: "scattergl",
//         },
//       ],
//       {
//         title: {
//           text: "Temperature",
//         },
//         xaxis: {
//           title: {
//             text: "Time(UTC)",
//           },
//         },
//         yaxis: {
//           title: {
//             text: "Temp(°C)",
//           },
//         },
//       },
//       {}
//     );

//     Plotly.newPlot(
//       "chart4",
//       [
//         {
//           x: [],
//           y: [],
//           mode: "lines+markers",
//           marker: { color: "#ff0066", size: 8 },
//           line: { width: 4 },
//           type: "scattergl",
//         },
//       ],
//       {
//         title: {
//           text: "CO2",
//         },
//         xaxis: {
//           title: {
//             text: "Time(UTC)",
//           },
//         },
//         yaxis: {
//           title: {
//             text: "CO2(ppm)",
//           },
//         },
//       },
//       {}
//     );

//     Plotly.newPlot(
//       "chart5",
//       [
//         {
//           x: [],
//           y: [],
//           mode: "lines+markers",
//           marker: { color: "pink", size: 8 },
//           line: { width: 4 },
//           type: "scattergl",
//         },
//       ],
//       {
//         title: {
//           text: "Pressure",
//         },
//         xaxis: {
//           title: {
//             text: "Time(UTC)",
//           },
//         },
//         yaxis: {
//           title: {
//             text: "hPa",
//           },
//         },
//       },
//       {}
//     );

//     Plotly.newPlot(
//       "chart6",
//       [
//         {
//           x: [],
//           y: [],
//           mode: "lines+markers",
//           marker: { color: "black", size: 8 },
//           line: { width: 4 },
//           type: "scattergl",
//         },
//       ],
//       {
//         title: {
//           text: "PM10",
//         },
//         xaxis: {
//           title: {
//             text: "Time(UTC)",
//           },
//         },
//         yaxis: {
//           title: {
//             text: "PM10(μg／m^3)",
//           },
//         },
//       },
//       {}
//     );
//   },
// };
</script>