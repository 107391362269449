<template>
  <div class="backgroundStyle flex h-screen justify-start items-center px-10">
    <div class="relativeitems-center  overflow-hidden px-10">
      <div class="w-full p-6 m-auto bg-white bg-opacity-30 rounded-md shadow-md lg:max-w-lg">
        <h1 class="text-3xl font-semibold text-center pb-5">Sign In</h1>
        <div class="space-y-4">
          <div>
            <input type="text" placeholder="Email Address" class="w-full input my-2" v-model="email"
              @keyup.enter="login($event)" />
            <input type="password" placeholder="Enter Password" class="w-full input my-2" v-model="password"
              @keyup.enter="login($event)" />
          </div>
          <div class="flex justify-start items-center pl-2">
            <div>
              <a href="/signup" class="text-xs text-gray-600 hover:underline hover:text-blue-600">Sign Up</a>
            </div>
          </div>
          <div class="flex justify-end">
            <button class="btn btn-primary" @click="login">Login</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const email = ref('');
const password = ref('');

onMounted(() => {
  const token = localStorage.getItem('token')
  if (token) {
    window.location.assign('/');
  }
})

const login = async () => {

  if (email.value && password.value) {
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}user/login/ppp`, { email: email.value, password: password.value })
      .then(data => {
        console.log(data)
        if(data.data.status){
          alert('Login Success !')
          localStorage.setItem('token', data.data.token)
          window.location.assign('/');
        }else{
          alert(`Login Fail ： ${data.data.message}`)
        }    
      }).catch(err => {
        console.log(err)
      }
      )
  } else {
    alert('email and password can be null !')
  }

}

</script>

<style scoped>
.backgroundStyle {
  background-image: url('../assets/banner.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

;</style>