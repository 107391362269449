<template>
<button class="mx-10 btn btn-sm my-4 px-4 p-1 bg-blue-600 text-white rounded-lg" onclick="join.showModal()">+ Join</button>
<dialog id="join" class="modal">
  <form method="dialog" class="modal-box">
    <h3 class="font-bold text-lg pb-3">Join Project</h3>
    <hr />
    <div class="flex items-center pt-10">
      <p class="py-4 pr-5">Project Name : </p>
      <select class="select w-full max-w-xs select-bordered " v-model="project_id">
        <option value="0" disabled>Project</option>
        <option v-for="each in apply_project" 
        :key="each.id"
        :value="each.id">{{ each.name }}</option>
      </select>
    </div>
    <div class="flex items-center py-5">
        <p class="py-5 pr-5">Invite Code :</p>
        <input type="text" placeholder="Invite Code" class="px-5 input input-bordered max-w-xs" v-model="invite_code" />
    </div>
    <div class="modal-action">
      <!-- if there is a button in form, it will close the modal -->
      <button class="btn btn-sm">Close</button>
      <button class="btn btn-accent btn-sm" @click="applyProjectWithCode">Submit</button>
    </div>
  </form>
  <form method="dialog" class="modal-backdrop">
    <button>close</button>
  </form>
</dialog>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const apply_project = ref([])
const project_id = ref(0)
const invite_code = ref('')


onMounted(() => {
  const token = localStorage.getItem('token')
  if (token) {
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/code`, {
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    }
  })
      .then(data => {
        console.log(data.data)
        apply_project.value = data.data.data
      }).catch(err => {
        console.log(err)
      })
  }
});

function applyProjectWithCode() {

  if (project_id.value !== 0) {
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/apply/code`, { project_id: project_id.value, code:invite_code.value }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
      'Content-Type': 'application/json',
    }
  })
      .then(data => {
        console.log(data)
        if(data.status === 201){
          alert('Apply Success')
          window.location.reload();
        }else{
          alert('Error Code')
        }
        
      }).catch(err => {
        console.log('Apply Fail', err)
        alert('Apply Fail. Please try again')
      })
  } else {
    alert('Please Choose a Project')
  }
}
</script>