<template>
  <div class="px-10">
    <div class="text-2xl font-bold px-10 py-5">
      Manage
    </div>
    <div class="px-10 flex mb-5 items-center">
      <div class="text-md px-2">Type : </div>
      <select class="select select-bordered" v-model="select_type">
        <option value="all">All</option>
        <option value="project">Project</option>
        <option value="aerobox">Aerobox</option>
      </select>
      <button class="btn mx-10" @click="selectType">Select</button>

    </div>
    <div class="px-10 pb-10">
      <table class="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="border-b border-t text-base">
          <tr>
            <th class="pt-2 pb-2">S/N</th>
            <th class="pt-2 pb-2">Type</th>
            <th class="pt-2 pb-2">is_Active</th>
            <th class="pt-2 pb-2">Name</th>
            <th class="pt-2 pb-2">User</th>
            <th class="pt-2 pb-2">Deparment</th>
            <th class="pt-2 pb-2">Nation</th>
            <th class="pt-2 pb-2">Notify</th>
            <th class="pt-2 pb-2">Deny</th>
            <th class="pt-2 pb-2">Request Date</th>
            <th class="pt-2 pb-2">Update Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="11">
              <div class="flex justify-center pt-20 w-full">
                <span class="loading loading-spinner loading-lg "></span>
              </div>
            </td>
          </tr>
          <tr v-if="!isLoading && project_apply.length === 0 && sitename_apply.length === 0">
            <td colspan="11">
              <div class="flex justify-center pt-20 w-full text-2xl">
                - You don't have any apply now! -
              </div>
            </td>
          </tr>

          <tr v-for="(apply, index) in project_apply" class="bg-white border-b text-base p-1">
            <td>{{ index + 1 }}</td>
            <td>Project</td>
            <td><input type="checkbox" class="toggle toggle-success" v-model="apply.is_active"
                @change="updateApplyStatus(apply)" /></td>
            <td v-if="apply.UserToProject">{{ apply.UserToProject.name }}</td>
            <td v-else> - </td>
            <td>{{ apply.ProjectToUser.username }}</td>
            <td>{{ apply.ProjectToUser.UserDepartment.department }}</td>
            <td>{{ apply.ProjectToUser.UserNation.nation }}</td>
            <td>
              <button class="
                   mr-6 px-2 p-1 my-2 text-sm text-blue-400 
                   rounded-lg border border-slate-400 border-blue-400
                   hover:text-white
                   hover:bg-blue-400
                   hover:border-transparent"
                @click="toggleNotifyModal(apply.ProjectToUser.username, apply.ProjectToUser.id)">
                Notify
              </button>
            </td>
            <td>
              <button class=" mr-6 px-2 p-1 my-2 text-sm text-red-400 
                   rounded-lg border border-slate-400 border-red-400
                   hover:text-white
                   hover:bg-red-400
                   hover:border-transparent" @click="rejectProjectApply(apply)">
                Reject
              </button>
            </td>
            <td class="text-sm text-gray-400">{{ apply.createdAt.slice(0, 10) }}</td>
            <td class="text-sm text-gray-400">{{ apply.updatedAt.slice(0, 10) }}</td>
          </tr>

          <template v-for="(each, index) in sitename_apply">
            <tr class="bg-white border-b text-base p-1" @click="toggleDetails(index)">
              <td>{{ each.aerobox_id.toUpperCase() }}</td>
              <td>Aerobox</td>
              <td><input type="checkbox" class="toggle toggle-success" @change="updateMetadataStatus(each, each.id)" />
              </td>
              <td>{{ each.name }}</td>
              <td>{{ each.manager_name }}</td>
              <td>Ncu</td>
              <td>Taiwan</td>
              <td>
                <button class="
                   mr-6 px-2 p-1 my-2 text-sm text-blue-400 
                   rounded-lg border border-slate-400 border-blue-400
                   hover:text-white
                   hover:bg-blue-400
                   hover:border-transparent" @click="toggleNotifyModal(each.manager_name, each.manager_id)">
                  Notify
                </button>
              </td>
              <td>
                <button class=" mr-6 px-2 p-1 my-2 text-sm text-red-400 
                   rounded-lg border border-slate-400 border-red-400
                   hover:text-white
                   hover:bg-red-400
                   hover:border-transparent" @click="rejectMetadataApply(each)">
                  Reject
                </button>
              </td>
              <td class="text-sm text-gray-400">{{ each.updatedAt.slice(0, 10) }}</td>
              <td class="text-sm text-gray-400">{{ each.updatedAt.slice(0, 10) }}</td>
            </tr>
            <tr v-if="showDetails[index]">
              <td colspan="11">
                <div class="bg-slate-200 rounded flex w-full p-3 m-2 text-base">
                  <div class="w-1/2">
                    <p class="pb-2">Lat : {{ each.lat }}</p>
                    <p class="pb-2">Lon : {{ each.lon }}</p>
                    <p class="pb-2">Alt : {{ each.alt }}</p>
                    <p class="pb-2">Manager name : {{ each.manager_name }}</p>
                    <p class="pb-2">Description : {{ each.discription }}</p>
                  </div>
                  <div class="w-1/2 pb-2">
                    Photo :
                    <div v-if="each.photo" class="flex w-max-full">
                      <Vue3ImagePreview >
                        <img :src="encodeURI(photoStartPath+each.photo.path)" class="p-2 h-auto w-1/2 imgshow" >
                        <img v-if="Object.keys(each.photo).length === 2" class="p-2 h-auto w-1/2 imgshow"
                          :src="encodeURI(photoStartPath+each.photo.path2)" alt="photo" />
                      </Vue3ImagePreview>
                    </div>
                    <div v-else class="text-center"> - no photo -</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <hr />
              </td>
            </tr>
          </template>

        </tbody>
      </table>
      <!-- Notify modal -->
      <dialog class="modal" :class="{ 'modal-open': showNotifyModal }">
        <form method="dialog" class="modal-box">
          <h3 class="font-bold text-lg pb-3">Create a notify</h3>
          <hr />
          <div class="items-center py-3">
            <p class="py-1 pr-5">User : {{ notify_user_name }}</p>
            <div class="flex">
              <p class="py-3 pr-5">Title : </p>
              <input type="text" placeholder="Type here" v-model="notify_title"
                class="input input-bordered w-full max-w-xs my-3" />
            </div>
            <div class="flex">
              <p class="py-1 pr-5">Notify : </p>
              <textarea class="textarea textarea-bordered w-full max-w-xs" placeholder="Bio"
                v-model="notify_content"></textarea>
            </div>
          </div>

          <div class="modal-action">
            <button class="btn" @click="toggleNotifyModal">Close</button>
            <button class="btn btn-primary" @click="sendNotify(notify_user_id.value)">Submit</button>
          </div>
        </form>
      </dialog>
    </div>
  </div>
</template>
<style scoped>
.imgshow {
  display: inline;
}
</style>
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { preview, vPreview, Vue3ImagePreview } from 'vue3-image-preview';

const photoStartPath = ref(process.env.VUE_APP_PHOTO_AEROBOX_SERVICE_URL);


const isLoading = ref(true)

const project_apply = ref([])
const all_project_apply = ref([])

// notify
const notify_user_id = ref(0)
const notify_user_name = ref('')
const notify_title = ref('')
const notify_content = ref('')

// aerobox apply
const sitename_apply = ref([])
const all_sitename_apply = ref([])
const showDetails = ref([]);


const select_type = ref('all')

onMounted(() => {
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/apply/list`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      console.log(data.data)
      project_apply.value = data.data.data
      all_project_apply.value = data.data.data
      axios.get(`${process.env.VUE_APP_API_AEROBOX_SERVICE_URL}pending`)
        .then(data => {
          console.log(data.data)
          // 設定sitename 是否打開的陣列
          showDetails.value = new Array(data.data.data.length).fill(false)

          sitename_apply.value = data.data.data
          all_sitename_apply.value = data.data.data
          isLoading.value = false
        }).catch(err => console.log(err))
    }).catch(err => {
      if (err.response && err.response.status === 401) {
        console.log(err.response)
        localStorage.removeItem('token')
        window.location.assign('/signin');
      } else {
        console.log(err.response)
      }
    })
})


// 更新狀態後會自動notify user 
function updateApplyStatus(apply) {
  axios.put(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/apply/${apply.id}`, { is_active: apply.is_active }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      notify_title.value = 'Your project apply has been approved'
      notify_content.value = 'You can view ' + apply.UserToProject.name + ' project in your dashboard'
      notify_user_id.value = apply.user_id
      sendNotify()
    })
    .catch(err => console.log(err))

  console.log('notify user')
}

function rejectProjectApply(project) {
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/apply/reject`, { project }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  }).then(data => {
    alert('success')
  }).catch(err => console.log(err))
}

function rejectMetadataApply(project) {
  axios.post(`${process.env.VUE_APP_API_AEROBOX_SERVICE_URL}pending/reject`, { project }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  }).then(data => {
    notify_title.value = `Your SiteName-${project.name} apply with ${project.aerobox_id} has been rejected`
    notify_content.value = 'If you have any question, please contact us'
    notify_user_id.value = project.manager_id
    sendNotify()
    //alert('success')
  }).catch(err => console.log(err))
}

function updateMetadataStatus(apply, id) {
  // TO DO send a notify to user
  console.log(apply)
  axios.post(`${process.env.VUE_APP_API_AEROBOX_SERVICE_URL}metadata/confirm`, { apply })
    .then(data => {
      console.log(data)
      // 新增成功後要刪除pending
      axios.post(`${process.env.VUE_APP_API_AEROBOX_SERVICE_URL}pending/delete`, { id })
        .then(res => {
          notify_title.value = 'Your metadata apply has been approved'
          notify_content.value = 'You can view detail data in aerobox : ' + apply.aerobox_id
          notify_user_id.value = apply.manager_id
          sendNotify()
        }).catch(err => console.log(err))
    })
    .catch(err => console.log(err))
}

// 展開對應得aerobox detail
const toggleDetails = (index) => {
  showDetails.value[index] = !showDetails.value[index];
};


function selectType() {
  if (select_type.value === 'all') {
    sitename_apply.value = all_sitename_apply.value
    project_apply.value = all_project_apply.value
  } else if (select_type.value === 'project') {
    sitename_apply.value = []
    project_apply.value = all_project_apply.value
  } else {
    sitename_apply.value = all_sitename_apply.value
    project_apply.value = []
  }
}

// notify modal
const showNotifyModal = ref(false);
function toggleNotifyModal(name, id) {
  console.log(name, id)
  showNotifyModal.value = !showNotifyModal.value;
  notify_user_name.value = name;
  notify_user_id.value = id
}


function sendNotify() {
  if (notify_title.value && notify_content.value) {
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}notify/send`, { event: notify_title.value, content: notify_content.value, user_id: notify_user_id.value }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
      .then(data => {
        alert('success')
        // 更新modal 資料
        notify_title.value = ''
        notify_content.value = ''
        showNotifyModal.value = false;
      }).catch(err => {
        console.log(err)
        alert(`create fail ${err}`)
      })
  } else {
    alert('Please enter content')
  }
}

</script>