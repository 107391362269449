<template>
  <button v-if="prop.siteName" class="btn btn-ghost btn-sm" @click="toggleModal">{{ prop.siteName.name }}</button>
  <button v-else class="btn btn-link btn-sm" @click="toggleModal">Edit</button>

  <dialog class="modal" :class="{ 'modal-open': showModal }">
    <form method="dialog" class="modal-box max-w-3xl">
      <h3 class="font-bold text-lg pb-3">Edit Site Name</h3>
      <hr />

      <div class="items-center py-3">
        <div class="flex items-center pt-5">
          <p class="pr-5">S/N : {{ prop.aerobox_id.toUpperCase() }}</p>
        </div>
        <div class="flex items-center pt-5">
          <p class="pr-5">Name：</p>
          <input type="text" placeholder="Aerobox Site Name " class="px-5 input input-bordered max-w-xs" v-model="name" />
        </div>

        <div class="flex items-center pt-5">
          <p class="pr-5">Altitude :</p>
          <input type="text" placeholder="Altitude" class="px-5 input input-bordered max-w-xs" v-model="alt" />
        </div>
        <div class="pt-5 pb-3">
          <p class="pr-5">Latitude and Longtitude：</p>
          <div style="width: auto; height: 400px">
            <l-map ref="map" v-model:zoom="zoom" :center="userLocation.position" @click="addMarker">
              <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
                name="OpenStreetMap"></l-tile-layer>
              <l-marker :lat-lng="userLocation.position"></l-marker>
            </l-map>
          </div>
        </div>

        <div class="flex items-center pt-5">
          <p class="pr-5">Install place：</p>
          <select class="select w-full max-w-xs select-bordered" v-model="place">
            <option value="indoor">Indoor</option>
            <option value="shelter">Shelter</option>
            <option value="outdoor">Outdoor</option>
          </select>
        </div>
        <div class="flex items-center pt-5">
          <p class="pr-5">Manager</p>
          <select class="select w-full max-w-xs select-bordered " v-model="user">
            <option value="{}" disabled>Manager</option>
            <option v-for="each in all_user" :value="each">{{ each.username }}</option>
          </select>
        </div>


        <div class="flex items-center pt-5">
          <p class="pr-5">Site description ：</p>
          <textarea class="textarea textarea-bordered textarea-lg" placeholder="Description"
            v-model="discription"></textarea>
        </div>
        <div class="flex items-center pt-5">
          <p class="pr-5">Picture：</p>

          <div v-if="!origin_photo" >
            <input type="file" class="file-input file-input-bordered w-full max-w-xs" @change="photoTest"
            accept=".png, .jpg, .jpeg" multiple />
          </div>
          

          <div v-if="origin_photo">
            <Vue3ImagePreview>
              <img :src="endoceURI(photoStartPath+prop.siteName.photo.path)" width="100" class="imgshow">
              <img v-if="Object.keys(prop.siteName.photo).length === 2" class="ms-3 imgshow"
                :src="endoceURI(photoStartPath+prop.siteName.photo.path2)" alt="photo" width="100" />
            </Vue3ImagePreview>   
          </div>
        </div>
        <div v-if="origin_photo" class="flex items-center pt-5">
            <p>重新上傳照片：</p>
            <input type="file" class="file-input file-input-bordered w-full max-w-xs" @change="photoTest"
              accept=".png, .jpg, .jpeg" multiple />
          </div>
      </div>

      <div class="modal-action">
        <button class="btn btn-sm" @click="toggleModal">Return</button>
        <button class="btn btn-accent btn-sm" @click="ApplySiteName">Save</button>
      </div>
    </form>

    <form method="dialog" class="modal-backdrop">
      <button @click="toggleModal">close</button>
    </form>
  </dialog>

  <div>

  </div>
</template>

<style scoped>
.imgshow {
  display: inline;
}
</style>

<script setup>
import axios from 'axios';
import { defineProps, ref, onMounted } from 'vue';

import { preview, vPreview, Vue3ImagePreview } from 'vue3-image-preview';

import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";

const photoStartPath = ref(process.env.VUE_APP_PHOTO_AEROBOX_SERVICE_URL);


const zoom = ref(8)

const prop = defineProps({
  aerobox_id: {
    type: String,
    required: true,
  },
  siteName: {
    type: String,
    required: false,
  },
});


// TO DO 好像會跑八次 怪怪的
const showModal = ref(false)

const name = ref('')
const alt = ref('')
const place = ref('indoor')
const discription = ref('')
const photo = ref(null)
const origin_photo = ref(null)

const all_user = ref([])
const user = ref({})

// 取得經緯度的地圖 用gps的方式定位 沒有開就預設學校
const userLocation = ref({ position: { lat: 24.968333, lng: 121.190833 } })


const addMarker = (e) => {
  userLocation.value.position = e.latlng
}


// 取得用戶位置
onMounted(() => {
  console.log(prop.siteName)
  if (!prop.siteName) {
    navigator.geolocation.getCurrentPosition((position) => {
      userLocation.value.position = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    })
  }
})


function photoTest(e) {
  const file = e.target.files;
  if (file.length > 2) {
    alert('You can only upload a maximum of two images！');
    e.target.value = ''
    return
  } else {
    photo.value = file
    for (let i = 0; i < file.length; i++) {
      const files = file[i];
      // 在这里执行上传文件的逻辑或其他操作
      console.log(`上传文件：${files.name}`);
    }
  }
}


async function ApplySiteName() {
  if (user.value.id === 0) {

    alert('Please choose a manager.')
  } else if (!name.value || !alt.value) {
    console.log(userLocation.value)
    alert('Please enter name and altitude.')
  } else {
    const formData = new FormData(); // 创建FormData对象

    // 圖片處理
    if (photo.value) {
      for (let i = 0; i < photo.value.length; i++) {
        console.log(photo.value)
        const file = photo.value[i];
        console.log(file)
        formData.append('photo', file);
      }
    }

    formData.append('name', name.value);
    formData.append('lon', userLocation.value.position.lng);
    formData.append('lat', userLocation.value.position.lat);
    formData.append('alt', alt.value);
    formData.append('place', place.value);
    formData.append('discription', discription.value);
    formData.append('manager_id', user.value.id);
    formData.append('manager_name', user.value.username);
    formData.append('aerobox_id', prop.aerobox_id);

    axios.post(`${process.env.VUE_APP_API_AEROBOX_SERVICE_URL}pending`,
      formData
      , {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data', // 设置请求头
        },
      })
      .then(data => {
        console.log(data)
        alert('Apply Success!')
        showModal.value = !showModal.value
      }).catch(err => {
        console.log(err)
        alert('Sitename has already been apply')
      })
  }
}


function toggleModal() {
  showModal.value = !showModal.value
  if (showModal.value) {

    // 顯示待編輯資料
    if (prop.siteName) {
      console.log(prop.siteName)
      name.value = prop.siteName.name
      discription.value = prop.siteName.discription
      place.value = prop.siteName.place
      if (prop.siteName.photo) {
        origin_photo.value = prop.siteName.photo.path
      }

      alt.value = prop.siteName.alt
      user.value = { id: prop.siteName.manager_id, username: prop.siteName.manager_name }
      userLocation.value.position = { lat: parseFloat(prop.siteName.lat), lng: parseFloat(prop.siteName.lon) }
    }

    // add user_id to select group
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}list/user/sitename`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
      .then(data => {
        console.log(data)
        all_user.value = data.data.data
      }).catch(err => console.log(err))
  }

}



</script>
