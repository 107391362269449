<template>
  <div class="px-10">
    <div class="flex justify-between">
      <div class="text-2xl font-bold px-10 py-5">
        User
      </div>
      <div class="pt-5">
        <button class="btn mx-4" @click="toggleAddDepartmentModal">Add an Affiliation</button>
        <button class="btn mx-4" @click="toggleAddNationModal">Add a Nation</button>
      </div>
    </div>
    <div class="px-10 flex items-center mb-5">
      <div class="text-md px-2">Project : </div>
      <select class="select select-bordered " v-model="project_id">
        <option value="0">All</option>
        <option v-for="each in all_project" :key="each.id" :value="each.id">{{ each.name }}</option>
      </select>
      <button class="btn mx-10" @click="listUserByProject">Select</button>

    </div>
    <div class="px-10">
      <table class="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="border-b border-t text-base">
          <tr>
            <th class="pt-2 pb-2">S/N</th>
            <th class="pt-2 pb-2">First Name</th>
            <th class="pt-2 pb-2">Last Name</th>
            <th class="pt-2 pb-2">Username</th>
            <th class="pt-2 pb-2">Email</th>
            <th class="pt-2 pb-2">Department</th>
            <th class="pt-2 pb-2">Nation</th>
            <th class="pt-2 pb-2">project</th>
            <th class="pt-2 pb-2">isAdmin</th>
            <th class="pt-2 pb-2">isActive</th>
            <th class="pt-2 pb-2">Join</th>
            <th class="pt-2 pb-2">Notify</th>
            <th class="pt-2 pb-2">Password</th>
          </tr>
        </thead>
        <tbody v-if="show_user.length > 0">
          <tr v-for="(user, index) in show_user" class="bg-white border-b text-base p-1">
            <td>{{ index + 1 }}</td>
            <td>{{ user.user.first_name }}</td>
            <td>{{ user.user.last_name }}</td>
            <td>{{ user.user.username }}</td>
            <td>{{ user.user.email }}</td>
            <td>{{ user.user.UserDepartment.department }}</td>
            <td>{{ user.user.UserNation.nation }}</td>
            <td class="text-center">{{ user.project_count }}</td>
            <td><input type="checkbox" class="toggle toggle-success" v-model="user.user.is_admin"
                @change="updateUserStatus(user.user.id, user.user.is_admin, user.user.is_active)" /></td>
            <td><input type="checkbox" class="toggle toggle-success" v-model="user.user.is_active"
                @change="updateUserStatus(user.user.id, user.user.is_admin, user.user.is_active)" /></td>
            <td class="text-sm text-gray-400">{{ user.user.createdAt.slice(0, 10) }}</td>
            <td>
              <button class="
                   mr-6 px-2 p-1 my-2 text-sm text-blue-400 
                   rounded-lg border border-slate-400 border-blue-400
                   hover:text-white
                   hover:bg-blue-400
                   hover:border-transparent" @click="toggleNotifyModal(user.user.id, user.user.username)">
                Notify
              </button>
            </td>
            <td>
              <button class="
                   mr-6 px-2 p-1 my-2 text-sm text-blue-400 
                   rounded-lg border border-slate-400 border-blue-400
                   hover:text-white
                   hover:bg-blue-400
                   hover:border-transparent" @click="toggleResetPasswordModal(user.user.id)">
                Reset
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center bold text-lg py-5" colspan="12"> - No Data -</td>
          </tr>
        </tbody>
      </table>

      <!-- Notify modal -->
      <dialog class="modal" :class="{ 'modal-open': showNotifyModal }">
        <form method="dialog" class="modal-box">
          <h3 class="font-bold text-lg pb-3">Create a notify</h3>
          <hr />
          <div class="items-center py-3">
            <p class="py-1 pr-5">User : {{ user_name }}</p>
            <div class="flex">
              <p class="py-3 pr-5">Title : </p>
              <input type="text" placeholder="Type here" class="input input-bordered w-full max-w-xs my-3"
                v-model="title" />
            </div>
            <div class="flex">
              <p class="py-1 pr-5">Notify : </p>
              <textarea class="textarea textarea-bordered w-full max-w-xs" placeholder="Bio" v-model="content"></textarea>
            </div>
          </div>

          <div class="modal-action">
            <button class="btn" @click="toggleNotifyModal">Close</button>
            <button class="btn btn-primary" @click="sendNotify">Submit</button>
          </div>
        </form>
      </dialog>


      <!-- Reste Password modal -->
      <dialog class="modal" :class="{ 'modal-open': showResetPasswordModal }">
        <form method="dialog" class="modal-box">
          <h3 class="font-bold text-lg pb-3">Create a notify</h3>
          <hr />
          <div class="items-center py-3">
            <div class="flex">
              <p class="py-3 pr-5">Password : </p>
              <input type="password" placeholder="Password" class="input input-bordered w-full max-w-xs my-3"
                v-model="password" />
            </div>
            <div class="flex">
              <p class="py-1 pr-5">Confirm Password : </p>
              <input type="password" placeholder="Confirm Password" class="input input-bordered w-full max-w-xs my-3"
                v-model="ConfirmPassword" />
            </div>
          </div>

          <div class="modal-action">
            <button class="btn" @click="toggleResetPasswordModal">Close</button>
            <button class="btn btn-primary" @click="sendRestPassword">Submit</button>
          </div>
        </form>
      </dialog>

      <!-- Add Department/Affiliation modal -->
      <dialog class="modal" :class="{ 'modal-open': showAddDepartmentModal }">
        <form method="dialog" class="modal-box">
          <h3 class="font-bold text-lg pb-3">Add an Affiliation</h3>
          <hr />
          <div class="items-center py-3">
            <div class="flex">
              <p class="py-3 pr-5">New Affiliation: </p>
              <input type="text" placeholder="Type here" class="input input-bordered w-full max-w-xs my-3"
                v-model="department_name" @keyup.enter="addDepartment($event)" />
            </div>
            <div class="flex">
              <p class="py-1 pr-5">Old Affiliation : </p>
              <textarea class="textarea textarea-bordered w-full max-w-xs" rows="4" disabled
                v-model="all_department"></textarea>
            </div>
          </div>

          <div class="modal-action">
            <button class="btn" @click="toggleAddDepartmentModal">Return</button>
            <button class="btn btn-primary" @click="addDepartment">Create</button>
          </div>
        </form>
      </dialog>


      <!-- Add Notion modal -->
      <dialog class="modal" :class="{ 'modal-open': showAddNationModal }">
        <form method="dialog" class="modal-box">
          <h3 class="font-bold text-lg pb-3">Add a Nation</h3>
          <hr />
          <div class="items-center py-3">
            <div class="flex">
              <p class="py-3 pr-5">New Nation : </p>
              <input type="text" placeholder="Type here" class="input input-bordered w-full max-w-xs my-3"
                v-model="nation_name" />
            </div>
            <div class="flex">
              <p class="py-1 pr-5">Old Nation : </p>
              <textarea class="textarea textarea-bordered w-full max-w-xs" rows="4" disabled
                v-model="all_nation"></textarea>
            </div>
          </div>

          <div class="modal-action">
            <button class="btn" @click="toggleAddNationModal">Return</button>
            <button class="btn btn-primary" @click="addNaiton">Create</button>
          </div>
        </form>
      </dialog>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
const showResetPasswordModal = ref(false);
const showNotifyModal = ref(false);
const showAddDepartmentModal = ref(false);
const showAddNationModal = ref(false);


const all_nation = ref('')
const all_department = ref('')
const nation_name = ref('')
const department_name = ref('')

const all_user = ref([])
const show_user = ref([])
const all_project = ref([])

const project_id = ref(0)

const user_id = ref();
const user_name = ref('')
const title = ref('')
const content = ref('')

const password = ref('')
const ConfirmPassword = ref('')

onMounted(() => {
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/user/count`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      all_user.value = data.data.data
      show_user.value = data.data.data
    }).catch(err => {
      if (err.response && err.response.status === 401) {
        console.log(err.response)
        window.location.assign('/signin');
      } else {
        console.log(err.response)
      }
    })

  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/list`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      all_project.value = data.data.data
    }).catch(err => {
      if (err.response && err.response.status === 401) {
        console.log(err.response)
        window.location.assign('/signin');
      } else {
        console.log(err.response)
      }
    })
})



function listUserByProject() {
  if (project_id.value === '0') {
    show_user.value= all_user.value
  } else {
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}user/${project_id.value}`)
      .then(data => {
        show_user.value = all_user.value.filter((obj1) => {
          return data.data.data.some((obj2) => obj2.email === obj1.user.email);
        });
      })
  }
}

function updateUserStatus(id, admin_status, active_status) {
  axios.put(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}user/status/${id}`, { is_active: active_status, is_admin: admin_status }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => alert('update success'))
    .catch(err => console.log(err))
}

function getList(name) {
  axios.get(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}${name}`)
    .then(data => {
      if (name === 'department') {
        all_department.value = data.data.data.map(department => department.department).join('\n');
      } else {
        all_nation.value = data.data.data.map(nation => nation.nation).join('\n');
      }
    }).catch(err => console.log(err))
}

function toggleAddDepartmentModal() {
  getList('department')
  showAddDepartmentModal.value = !showAddDepartmentModal.value;
}

function toggleAddNationModal() {
  getList('nation')
  showAddNationModal.value = !showAddNationModal.value;
}
function toggleNotifyModal(id, name) {
  user_id.value = id
  user_name.value = name
  showNotifyModal.value = !showNotifyModal.value;
}

function toggleResetPasswordModal(id) {
  user_id.value = id
  showResetPasswordModal.value = !showResetPasswordModal.value;
}


// Modal 相關
function addDepartment(event) {
  event.stopPropagation()
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}department`, { department: department_name.value }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      alert('create success')
      // 更新modal 資料
      department_name.value = ''
      getList('department')

    }).catch(err => {
      console.log(err)
      alert(`create failed ${err}`)
    })
}

function addNaiton() {
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}nation`, { nation: nation_name.value }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      console.log(data)
      alert('create successs')
      // 更新modal 資料
      nation_name.value = ''
      getList('nation')

    }).catch(err => {
      console.log(err)
      alert(`create failed ${err}`)
    })
}

function sendNotify() {
  if (title.value && content.value) {
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}notify/send`, { event: title.value, content: content.value, user_id: user_id.value }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
      .then(data => {
        console.log(data)
        alert('create success')
        // 更新modal 資料
        title.value = ''
        content.value = ''
        showNotifyModal.value = !showNotifyModal.value;
      }).catch(err => {
        console.log(err)
        alert(`create failed ${err}`)
      })
  } else {
    alert('please enter content')
  }
}

function sendRestPassword() {

  if (password.value === ConfirmPassword.value) {
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}user/pw/${user_id.value}`, { password: password.value }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
      .then(data => {
        console.log(data)
        alert('Reset Password Success')
        // 更新modal 資料
        password.value = ''
        ConfirmPassword.value = ''
        showResetPasswordModal.value = !showResetPasswordModal.value;
      }).catch(err => {
        console.log(err)
        alert(`create failed ${err}`)
      })
  } else {
    alert('Please check password again')
  }
}
</script>