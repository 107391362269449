<template>
  <button class="
      mr-6 p-1 text-sm text-blue-400 
      rounded-lg border border-slate-400 border-blue-400
      hover:text-white
      hover:bg-blue-400
      hover:border-transparent" @click="toggleModal">
    Download
  </button>

  <dialog class="modal" :class="{ 'modal-open': showModal }">
    <form method="dialog" class="modal-box mx-3  max-w-xl text-start">
      <h3 class="font-bold text-lg pb-3 text-start">Download Aerobox Data</h3>
      <hr />

      <div class="pt-3 text-lg text-bold"> S/N : {{ prop.aerobox_id.toUpperCase() }} </div>


      <div class="p-3 text-lg"> Select Interval : </div>
      <div class="flex items-center pt-5">
        <div class="flex items-center pl-3 pointer-events-none">
          <svg class="w-7 h-7 text-gray-500 dark:text-gray-400 mr-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
            fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
          </svg>
        </div>
        <Datepicker v-model="start_date" class="w-32" />

        <span class="mx-6 text-gray-500"> ~ </span>

        <Datepicker v-model="end_date" class="w-32" />
      </div>
      <label class="label">
        <span class="label-text-alt ps-3">Max is 30 days</span>
      </label>


      <div class="items-center px-3">
        <p class="py-3 pr-5 text-lg pt-4">Summary：</p>

        <div class="stats shadow mr-5">

          <div class="stat">
            <div class="stat-title">Today</div>
            <div v-if="todayCount > 0" class="stat-value">{{ todayCount }}</div>
            <div v-else-if="todayCount === 0" class="text-center text-blod"> - No data - </div>
            <div v-else><span class="loading loading-dots loading-lg"></span></div>
          </div>

          <div class="stat">
            <div class="stat-title">This Week</div>
            <div v-if="thisWeekCount > 0" class="stat-value">{{ thisWeekCount }}</div>
            <div v-else-if="thisWeekCount === 0" class="text-center text-blod"> - No data - </div>
            <div v-else><span class="loading loading-dots loading-lg"></span></div>
          </div>

          <div class="stat">
            <div class="stat-title">This Month</div>
            <div v-if="thisMonthCount > 0" class="stat-value">{{ thisMonthCount }}</div>
            <div v-else-if="thisMonthCount === 0" class="text-center text-blod"> - No data - </div>
            <div v-else><span class="loading loading-dots loading-lg"></span></div>
          </div>
        </div>
        <label class="label">
          <span class="label-text-alt ps-3">Unit: Number of Data</span>
        </label>
        <label class="label">
          <span class="label-text-alt ps-3">P.S. The amount of data is huge, please be patient. If you select over 10
            days, may be wait for ten minutes.</span>
        </label>
      </div>


      <div class="modal-action">
        <button class="btn mx-4 btn-sm" @click="toggleModal">Cancel</button>
        <button v-if="isLoading" class="btn  btn-accent btn-sm" disabled><span
            class="loading loading-dots"></span>Download</button>
        <button v-else class="btn  btn-accent btn-sm" @click="download">Download</button>
      </div>
    </form>
  </dialog>
</template>
<script setup>

// still problem
import { onMounted, ref } from 'vue'
import Datepicker from 'vue3-datepicker'
import axios from 'axios';
import { defineProps } from 'vue';

const prop = defineProps({
  aerobox_id: {
    type: String,
    required: true,
  },
});


const thisMonthCount = ref(-1)
const thisWeekCount = ref(-1)
const todayCount = ref(-1)


const start_date = ref(new Date())
const end_date = ref(new Date())

const showModal = ref(false)

const isLoading = ref(false)

function toggleModal() {
  showModal.value = !showModal.value
  if (showModal.value) {

    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}aerobox/download`, { deviceId: prop.aerobox_id }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    }).then(data => {
      console.log(data.data)
      if (data.data.payload) {
        thisMonthCount.value = data.data.payload.length
        const today = new Date();
        // 获取本周的起始日期（假设周从星期天开始）
        const thisWeekStartUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - today.getUTCDay(), 0, 0, 0));

        console.log(thisWeekStartUTC)
        today.setUTCHours(0, 0, 0, 0); // 将时间调整为当天的起始时间


        // 使用数组的 filter 方法来筛选今天和本周的数据
        const todayData = data.data.payload.filter(item => new Date(item.RecvTimestamp).getTime() >= today);
        const thisWeekData = data.data.payload.filter(item => new Date(item.RecvTimestamp) >= thisWeekStartUTC.getTime());

        // 获取数据的长度
        thisWeekCount.value = thisWeekData.length
        todayCount.value = todayData.length
      } else {
        thisMonthCount.value = 0
        thisWeekCount.value = 0
        todayCount.value = 0
      }

    }).catch(err => {
      console.log(err)
    })
  }
}

function download() {
  const interval = end_date.value - start_date.value
  const days = interval / (1000 * 60 * 60 * 24)

  if (days > 31) {
    alert('Over 30 days!')
  } else if (end_date.value < start_date.value) {
    alert('Please select right date!')
  } else {
    isLoading.value = true
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}aerobox/download/data`, { deviceId: prop.aerobox_id, before: end_date.value.getTime(), after: start_date.value.getTime() }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    }).then(data => {

      const blob = new Blob([data.data], { type: 'text/csv' });

      // 创建一个下载链接
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv'; // 设置下载文件名

      // 模拟点击下载链接
      a.click();

      // 释放URL对象
      window.URL.revokeObjectURL(url);
      isLoading.value = false

    })
      .catch(err => console.log(err))
  }

}


</script>
