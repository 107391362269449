import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import Dashboard from './page/Dashboard.vue'
import SignIn from './page/SignIn.vue'
import SignUp from './page/SignUp.vue'
import QuickPlot from "./page/QuickPlot.vue";
import ProjectDetail from "./page/ProjectDetail.vue";
import ManageAccount from "./page/ManageAccount.vue";
import ApplyManage from "./page/ApplyManage.vue";
import ProjectManage from "./page/ProjectManage";
import UserManage from "./page/UserManage";
import AeroboxManage from "./page/AeroboxManage";

import './index.css'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
//import { faChartBar } from '@fortawesome/free-regular-svg-icons';

import VueGoogleMaps from '@fawmi/vue-google-maps'

library.add(faChartBar);

const routes = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "/signin",
        name: "SignIn",
        component: SignIn,
        meta: {
          title: "SignIn",
        },
      },
      {
        path: "/signup",
        name: "SignUp",
        component: SignUp,
        meta: {
          title: "SignUp",
        },
      },
      {
        path: "/manageaccount",
        name: "ManageAccount",
        component: ManageAccount,
        meta: {
          title: "ManageAccount",
        },
      },
      {
        path: "/quickplot/:id",
        name: "Quickplot",
        component: QuickPlot,
        props: true,
        meta: {
          title: "Quickplot",
        },
      },
      {
        path: "/:id",
        name: "ProjectDetail",
        component: ProjectDetail,
        props: true,
        meta: {
          title: "ProjectDetail",
        },
    },
    {
      path: "/user/manage",
      name: "UserManage",
      component: UserManage,
      meta: {
        title: "UserManage",
      },
    },
    {
      path: "/apply/manage",
      name: "ApplyManage",
      component: ApplyManage,
      meta: {
        title: "ApplyManage",
      },
    },
    {
      path: "/project/manage",
      name: "ProjectManage",
      component: ProjectManage,
      meta: {
        title: "ProjectManage",
      },
    },
    {
      path: "/aerobox/manage",
      name: "AeroboxManage",
      component: AeroboxManage,
      meta: {
        title: "AeroboxManage",
      },
    },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'ideaSky';

  next();
});


// const cors = require('cors');

const app = createApp(App);
app.use(router);

app.use(VueGoogleMaps, {
  load: {
      key: process.env.VUE_APP_GOOGLE_MAP_KEY,
  },
})




// app.use(cors({
//   origin: ['http://localhost:8080'],
// }))
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app');
