<template>
  <table class="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead class="border-b border-t text-base">
      <tr class="text-center">
        <th class="pt-2 pb-2">Index</th>
        <th class="pt-2 pb-2 text-center">Photo</th>
        <th class="pt-2 pb-2">S/N</th>
        <th class="pt-2 pb-2">SiteName</th>
        <th class="pt-2 pb-2">Status</th>
        <th class="pt-2 pb-2">Retired</th>
        <th class="pt-2 pb-2">Notify</th>
        <th class="pt-2 pb-2">Project</th>
        <th class="pt-2 pb-2">Last seen</th>
        <th class="pt-2 pb-2">Metadata</th>
        <th class="pt-2 pb-2">API</th>
        <th class="pt-2 pb-2">Manage</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="prop.all_aerobox.length === 0">
        <td colspan="12">
          <div class="flex justify-center pt-20 w-full">
            <span class="loading loading-spinner loading-lg "></span>
          </div>
        </td>
      </tr>
      <tr v-for="(each, index) in prop.all_aerobox" class="bg-white border-b text-base p-1 text-center">
        <td class="text-center">{{ index + 1 }}</td>
        <td>
          <div class="flex justify-center items-center">
            <!-- <img :src="each.photo" alt="photo" width="100" height="75" @click="show" /> -->
            <div v-if="each.site" class="py-2">
              <div v-if="each.site.photo" class="flex">
                <Vue3ImagePreview>
                  <img :src="encodeURI(photoStartPath+each.site.photo.path)" width="100" class="imgshow">
                  <img v-if="Object.keys(each.site.photo).length === 2" class="ms-3 imgshow"
                    :src="encodeURI(photoStartPath+each.site.photo.path2)" alt="photo" width="100" />
                </Vue3ImagePreview>
              </div>
            </div>

          </div>
        </td>
        <td>{{ each.data.id.toUpperCase() }}</td>
        <td v-if="each.site">
          <p class="flex justify-center">{{ each.site.name }}</p>
        </td>
        <td v-else>
          <p class="flex justify-center"> - </p>
        </td>
        <td>
          <div v-if="each.data.status === 'offline'" class="flex justify-start items-center">
            <p class="pr-2 pt-1">Offline </p>
            <p class="bg-gray-300 w-5 h-5"></p>
          </div>
          <div v-else class="flex justify-start items-center">
            <p class="pr-2 pt-1">Online </p>
            <p class="bg-green-300 w-5 h-5"></p>
          </div>
        </td>
        <td><input type="checkbox" class="toggle toggle-success" v-model="each.data.is_retired"
            @change="updateAeroboxStatus(each.data.id, each.data.is_retired, each.data.is_notify)" /></td>
        <td><input type="checkbox" class="toggle toggle-success" v-model="each.data.is_notify"
            @change="updateAeroboxStatus(each.data.id, each.data.is_retired, each.data.is_notify)" /></td>

        <td>
          <p class="flex justify-center">{{ each.data.project }}</p>
        </td>
        <td v-if="each.data.receiveTime" class="text-sm text-gray-400">{{ moment(each.data.receiveTime) }}</td>
        <td v-else>
          <p class="flex justify-center"> - </p>
        </td>
        <td>
          <button class="
                   mr-6 px-2 p-1 my-2 text-sm text-blue-400 
                   rounded-lg border border-slate-400 border-blue-400
                   hover:text-white
                   hover:bg-blue-400
                   hover:border-transparent" @click="toggleLogModal(each.data.id)">
            Log
          </button>
        </td>
        <td>
          <Download :aerobox_id="each.data.id" />
        </td>
        <td>
          <button class="
                   mr-6 px-2 p-1 my-2 text-sm text-blue-400 
                   rounded-lg border border-slate-400 border-blue-400
                   hover:text-white
                   hover:bg-blue-400
                   hover:border-transparent" @click="toggleSiteNameModal(each.site, each.data.id)">
            Edit
          </button>
        </td>
      </tr>
    </tbody>
  </table>



  <!-- Site name Modal -->
  <dialog class="modal" :class="{ 'modal-open': showSiteNameModal }">
    <form method="dialog" class="modal-box max-w-3xl">
      <h3 class="font-bold text-lg pb-3">Edit Site Name</h3>
      <hr />

      <div class="items-center py-3">
        <div class="flex items-center pt-5">
          <p class="pr-5">S/N : {{ aerobox_id.toUpperCase() }}</p>
        </div>
        <div class="flex items-center pt-5">
          <p class="pr-5">Name：</p>
          <input type="text" placeholder="Aerobox Site Name " class="px-5 input input-bordered max-w-xs" v-model="name" />
        </div>

        <div class="flex items-center pt-5">
          <p class="pr-5">Altitude :</p>
          <input type="text" placeholder="Altitude" class="px-5 input input-bordered max-w-xs" v-model="alt" />
        </div>
        <div class="pt-5 pb-3">
          <p class="pr-5">Latitude and Longtitude：</p>
          <div style="width: auto; height: 400px">
            <l-map ref="map" v-model:zoom="zoom" :center="userLocation.position" @click="addMarker">
              <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
                name="OpenStreetMap"></l-tile-layer>
              <l-marker :lat-lng="userLocation.position"></l-marker>
            </l-map>
          </div>

        </div>

        <div class="flex items-center pt-5">
          <p class="pr-5">Install place：</p>
          <select class="select w-full max-w-xs select-bordered" v-model="place">
            <option value="indoor">Indoor</option>
            <option value="shelter">Shelter</option>
            <option value="outdoor">Outdoor</option>
          </select>
        </div>
        <div class="flex items-center pt-5">
          <p class="pr-5">Manager</p>
          <select class="select w-full max-w-xs select-bordered " v-model="user">
            <option value="0" disabled>Manager</option>
            <option v-for="each in all_user" :value="each">{{ each.username }}</option>
          </select>
        </div>


        <div class="flex items-center pt-5">
          <p class="pr-5">Site description ：</p>
          <textarea class="textarea textarea-bordered textarea-lg" placeholder="Description"
            v-model="discription"></textarea>
        </div>
        <div class="flex items-center pt-5">
          <p class="pr-5">Picture：</p>
          <div v-if="!origin_photo" >
            <input type="file" class="file-input file-input-bordered w-full max-w-xs" @change="photoTest"
            accept=".png, .jpg, .jpeg" multiple />
          </div>
          

          <div v-if="origin_photo">
            <Vue3ImagePreview>
              <img :src="encodeURI(photoStartPath+each.site.photo.path)" width="100" class="imgshow">
              <img v-if="Object.keys(origin_photo).length === 2" class="ms-3 imgshow"
                :src="encodeURI(photoStartPath+each.site.photo.path2)" alt="photo" width="100" />
            </Vue3ImagePreview>   
          </div>
        </div>
        <div v-if="origin_photo" class="flex items-center pt-5">
            <p>重新上傳照片：</p>
            <input type="file" class="file-input file-input-bordered w-full max-w-xs" @change="photoTest"
              accept=".png, .jpg, .jpeg" multiple />
          </div>
      </div>

      <div class="modal-action">
        <button class="btn" @click="toggleSiteNameModal(0)">Return</button>
        <button class="btn btn-primary" @click="ApplySiteName">Create</button>
      </div>
    </form>

    <form method="dialog" class="modal-backdrop">
      <button @click="toggleSiteNameModal(0)">close</button>
    </form>
  </dialog>


  <!-- log modal -->
  <dialog class="modal" :class="{ 'modal-open': showLogModal }">
    <form method="dialog" class="modal-box">
      <h3 class="font-bold text-lg pb-3">Project Log</h3>
      <div v-if="aerobox_log.length === 0" class="text-center pt-5"> - No history - </div>
      <div v-for="log in aerobox_log" class="items-center py-3">
        <div class="flex">
          <p class="py-1 pr-5 w-1/2">Name : {{ log.name }}</p>
          <p class="py-1 pr-5">Lat : {{ log.lat }}</p>
        </div>
        <div class="flex">
          <p class="py-1 pr-5 w-1/2">User : {{ log.manager_name }}</p>
          <p class="py-1 pr-5">Lon : {{ log.lon }}</p>
        </div>
        <div class="flex">
          <p class="py-1 pr-5 w-1/2">update_date : {{ log.updatedAt.slice(0, 10) }}</p>
          <p class="py-1 pr-5">alt : {{ log.alt }}</p>
        </div>
        <div class="flex">
          <p class="py-1 pr-5 w-1/2">discription : {{ log.discription }}</p>
        </div>
        <div class="flex">
          <p v-if="!log.photo" class="py-1 pr-5 w-1/2 pb-5">photo : - No -</p>
          <div v-if="log.photo" class="flex w-max-full">
            <div>photo:</div>
            <Vue3ImagePreview>
              <img :src="encodeURI(photoStartPath+each.site.photo.path)" class="p-2 h-auto w-1/2 imgshow">
              <img v-if="Object.keys(log.photo).length === 2" class="p-2 h-auto w-1/2 imgshow"
                :src="encodeURI(photoStartPath+each.site.photo.path2)" alt="photo" />
            </Vue3ImagePreview>
          </div>
        </div>
        <hr />
      </div>

      <div class="modal-action">
        <button class="btn" @click="toggleLogModal(0)">Close</button>
      </div>
    </form>
  </dialog>
</template>
<style scoped>
.imgshow {
  display: inline;
}
</style>
<script setup>
import moment from 'moment';
import axios from 'axios';
import { defineProps, ref } from 'vue';

import Download from '../ProjectDetail/Download.vue'

import { preview, vPreview, Vue3ImagePreview } from 'vue3-image-preview';


import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";

const photoStartPath = ref(process.env.VUE_APP_PHOTO_AEROBOX_SERVICE_URL);

const zoom = ref(8)

const prop = defineProps({
  all_aerobox: {
    type: Array,
    required: true,
  }
});




// sitename modal
const name = ref('')
const alt = ref('')
const place = ref('indoor')
const discription = ref('')

const all_user = ref([])
const user = ref({})
const photo = ref()
const origin_photo = ref()

// 取得經緯度的地圖 用gps的方式定位 沒有開就預設學校
const userLocation = ref({ position: { lat: 24.968333, lng: 121.190833 } })

const addMarker = (e) => {
  userLocation.value.position = e.latlng
}


const aerobox_id = ref('')
const showSiteNameModal = ref(false);

function toggleSiteNameModal(data, aerobox) {

  showSiteNameModal.value = !showSiteNameModal.value;

  if (showSiteNameModal.value) {
    if (data) {
      name.value = data.name
      discription.value = data.discription
      place.value = data.place
      alt.value = data.alt
      if (data.photo) {
        origin_photo.value = data.photo
      }
      user.value = { id: data.manager_id, username: data.manager_name }
      userLocation.value.position = { lat: parseFloat(data.lat), lng: parseFloat(data.lon) }
    } else {
      name.value = ""
      discription.value = ""
      place.value = 'indoor'
      alt.value = ""
      origin_photo.value = null
      navigator.geolocation.getCurrentPosition((position) => {
        userLocation.value.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    }
    aerobox_id.value = aerobox

    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}list/user/sitename`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
      .then(data => {
        console.log(data)
        all_user.value = data.data.data
      })

  }
}

function photoTest(e) {
  const file = e.target.files;
  if (file.length > 2) {
    alert('You can only upload a maximum of two images！');
    e.target.value = ''
    return
  } else {
    photo.value = file
  }
}

function ApplySiteName() {
  if (user.value.id === 0) {

    alert('Please choose a manager.')
  } else if (!name.value || !alt.value) {
    console.log(userLocation.value)
    alert('Please enter name and altitude.')
  } else {

    const formData = new FormData(); // 创建FormData对象

    if (photo.value) {
      for (let i = 0; i < photo.value.length; i++) {
        const file = photo.value[i];
        formData.append('photo', file);
      }
    }

    formData.append('name', name.value);
    formData.append('lon', userLocation.value.position.lng);
    formData.append('lat', userLocation.value.position.lat);
    formData.append('alt', alt.value);
    formData.append('place', place.value);
    formData.append('discription', discription.value);
    formData.append('manager_id', user.value.id);
    formData.append('manager_name', user.value.username);
    formData.append('aerobox_id', aerobox_id.value);

    axios.post(`${process.env.VUE_APP_API_AEROBOX_SERVICE_URL}metadata/admin`, formData
      , {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data', // 设置请求头
        },
      })
      .then(data => {
        console.log(data)
        alert('Save Success!')
        window.location.reload();
      })
      .catch(err => console.log(err))
  }

}


const showLogModal = ref(false);

const aerobox_log = ref([])

function toggleLogModal(id) {

  showLogModal.value = !showLogModal.value;

  if (id !== 0) {
    axios.get(`${process.env.VUE_APP_API_AEROBOX_SERVICE_URL}metadata/${id}`)
      .then(data => {
        console.log(data.data)
        aerobox_log.value = data.data.data
      }).catch(err => console.log(err))
  }
}

function updateAeroboxStatus(id, is_retired, is_notify) {
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}aerobox/status`, { id, is_retired, is_notify }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      alert('update success!')
      console.log(data)
    })
    .catch(err => {
      alert('update failed!')
      console.log(err)
    })

}

</script>