<template>
  <div class="px-10 pt-5">
    <a class="px-5" href="/"> &lt Dashboard</a>
    <div class="text-2xl font-bold px-10 py-5">
      Areobox
    </div>
    <div class="px-10">
      <table class="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="border-b border-t text-base">
          <tr>
            <th class="pt-2 pb-2">S/N</th>
            <th class="pt-2 pb-2">Name</th>
            <th class="pt-2 pb-2">Status</th>
            <th class="pt-2 pb-2">QuickPlot</th>
            <th class="pt-2 pb-2">PM</th>
            <th class="pt-2 pb-2">CO2</th>
            <th class="pt-2 pb-2">Temp</th>
            <th class="pt-2 pb-2">RH</th>
            <th class="pt-2 pb-2">Last seen</th>
            <th class="pt-2 pb-2">API</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="aerobox in aerobox_list" class="bg-white border-b text-base p-1 ">
            <td>{{ aerobox.deviceId.toUpperCase() }}</td>

            <td>
              <SiteName :aerobox_id="aerobox.deviceId" :siteName="hasSiteName(aerobox)" />
              <!-- <button class="btn btn-link" @click="openModal">Edit</button> -->
            </td>
            <td class="flex justify-start items-center py-3">
              <p class="pr-2 pt-1">{{ aerobox.status }} </p>
              <p v-if="aerobox.status === 'online'" class="bg-green-300 w-5 h-5"></p>
              <p v-else class="bg-gray-300 w-5 h-5"></p>
            </td>
            <td class="pl-5" @click="router.push(`/quickplot/${aerobox.deviceId}`)">
              <!-- <font-awesome-icon :icon="['fas', 'chart-bar']" /> -->
              <img src="../assets/plot.png" width="25" />
            </td>
            <td v-if="aerobox.data">{{ aerobox.data[0].Payload.Pm2_5 }}</td>
            <td v-else> - </td>

            <td v-if="aerobox.data">{{ aerobox.data[0].Payload.Co2 }}</td>
            <td v-else> - </td>

            <td v-if="aerobox.data">{{ aerobox.data[0].Payload.Temp }}</td>
            <td v-else> - </td>
            <td v-if="aerobox.data">{{ aerobox.data[0].Payload.Rh }}</td>
            <td v-else> - </td>
            <td v-if="aerobox.data" class="text-sm text-gray-400">{{ ChangeDateType(aerobox.data[0].DeviceTimestamp) }}
            </td>
            <td v-else> - </td>
            <td>
              <Download :aerobox_id="aerobox.deviceId" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import Download from '@/components/ProjectDetail/Download.vue'
import SiteName from '@/components/ProjectDetail/SiteName.vue'
import { useRouter } from 'vue-router';
import axios from 'axios';

import { defineProps } from 'vue';
const prop = defineProps(['id']);

const router = useRouter()

const aerobox_list = ref([])
const metadata_list = ref([])

onMounted(() => {
  // 列出所有aerobox
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}aerobox/${prop.id}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      // 把空值篩掉 空值代表後端的那個aerobox已是retire狀態
      aerobox_list.value = data.data.data.filter(element => element !== null)
      axios.get(`${process.env.VUE_APP_API_AEROBOX_SERVICE_URL}metadata`)
        .then(data => {
          console.log(data.data)
          metadata_list.value = data.data.data
        }).catch(err => console.log(err))
    }).catch(err => {
      if (err.response && err.response.status === 401) {
        console.log(err.response)
        localStorage.removeItem('token')
        window.location.assign('/signin');
      } else {
        console.log(err.response)
      }
    })
})

function hasSiteName(apply) {
  const data = metadata_list.value.filter(each => each.aerobox_id === apply.deviceId)
  if (data.length) {
    return data[0]
  } else {
    return null
  }
}

function ChangeDateType(date) {
  const temp = new Date(date)
  return temp.toLocaleString("en-US")
}

</script>

  