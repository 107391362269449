<template>
  <div class="overflow-x-auto w-screen px-10">
    <div v-if="main_project[0] === 0" class="flex justify-center pt-40">
      <span class="loading loading-spinner loading-lg "></span>
    </div>
    <div v-else-if="main_project.length > 0" class="inline-flex  z-0">
      <draggable v-model="main_project" class="inline-flex" ghost-class="ghost" chosen-class="chosenClass" animation="200"
        item-key="id" @change="onProjectChange()">
        <template #item="{ element: main, index }">
          <div>

            <div class="pb-5 aspect-vedio max-w-sm border rounded-lg w-72 mx-5">
              <div class="p-2 bg-blue-300 flex justify-between">
                <p class="pl-5 font-bold">{{ main.project.name }}</p>

                <div class="dropdown dropdown-right">
                  <label tabindex="0" @click="dropdownBtn(main.project)"><svg xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 pt-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M10 3a2 2 0 100 4 2 2 0 000-4zM16 3a2 2 0 100 4 2 2 0 000-4zM4 3a2 2 0 100 4 2 2 0 000-4z"
                        clip-rule="evenodd" />
                    </svg></label>
                  <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                    <li><a @click="showCode">Generate Invitation Code</a></li>
                    <li><a @click="deleteProject">Delete Project</a></li>
                  </ul>
                </div>

              </div>
              <div class="pt-5 pl-6 text-base bg-white">
                <div class="flex justify-between">
                  <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm">Online Aerobox</p>
                  <div class="flex justify-end mr-5">
                    <p class="pr-3">{{ main.online }}</p>
                    <p class="bg-green-300 w-5 h-5"></p>
                  </div>
                </div>
                <div class="flex justify-between">
                  <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm">Offline Aerobox</p>
                  <div class="flex justify-end mr-5">
                    <p class="pr-3">{{ main.offline }} </p>
                    <p class="bg-gray-300 w-5 h-5"></p>
                  </div>
                </div>
                <div class="flex justify-between pt-2 items-end text-sm items-center content-center">
                  <p>Onwer : {{ main.project.UserCreateProject.username }}</p>
                  <button class="
                    text-sm mr-6 p-1 text-blue-300 
                    rounded-lg border
                    hover:text-white
                    hover:bg-blue-400
                    hover:border-transparent" @click="router.push(`/${main.project.id}`)">
                    View
                  </button>
                </div>
              </div>
            </div>

            <div>
              <draggable v-if="main.project.Parent.length" v-model="main.project.Parent" ghost-class="ghost"
                chosen-class="chosenClass" animation="200" item-key="id" @change="onSubProjectChange(main.project)">
                <template #item="{ element: all, index }" class="pb-5">
                  <div class="pb-5 aspect-vedio max-w-sm border rounded-lg w-72 mx-5 mt-5">
                    <div class="p-2 bg-orange-300 flex justify-between">
                      <p class="pl-5 font-bold">{{ all.name }} </p>

                      <div class="dropdown dropdown-right">
                        <label tabindex="0" @click="dropdownBtn(all)">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pt-2" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M10 3a2 2 0 100 4 2 2 0 000-4zM16 3a2 2 0 100 4 2 2 0 000-4zM4 3a2 2 0 100 4 2 2 0 000-4z"
                              clip-rule="evenodd" />
                          </svg></label>
                        <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                          <li><a @click="showCode">Generate Invitation Code</a></li>
                          <li><a @click="deleteProject">Delete Project</a></li>
                        </ul>
                      </div>

                    </div>
                    <div class="pt-5 pl-6 text-base bg-white">
                      <div class="flex justify-between">
                        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm">Online Aerobox</p>
                        <div class="flex justify-end mr-5">
                          <p class="pr-3">{{ all_project.find(each => each.project.id === all.id).online }}</p>
                          <p class="bg-green-300 w-5 h-5"></p>
                        </div>
                      </div>
                      <div class="flex justify-between">
                        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm">Offline Aerobox</p>
                        <div class="flex justify-end mr-5">
                          <p class="pr-3">{{ all_project.find(each => each.project.id === all.id).offline }}</p>
                          <p class="bg-gray-300 w-5 h-5"></p>
                        </div>
                      </div>
                      <div class="flex justify-between pt-2 items-end text-sm items-center content-center">
                        <p>Onwer : {{ all.UserCreateProject.username }}</p>
                        <button class="
                    text-sm mr-6 p-1 text-blue-300 
                    rounded-lg border
                    hover:text-white
                    hover:bg-blue-400
                    hover:border-transparent" @click="router.push(`/${all.id}`)">
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
              <div
                class="pb-3 max-w-sm border border-gray-200 rounded-lg w-72 h-44 mt-5 mx-5 flex justify-center items-center"
                @click="toggleModal(main.project.id)" style="cursor: pointer">
                <div class="text-2xl">
                  + sub-project
                </div>
              </div>
            </div>
          </div>
        </template>
      </draggable>

    </div>
    <div v-else>
      <p class="pt-10 text-center text-3xl"> - You don't have any project -</p>
    </div>



    <!-- Create Sub-project Modal -->
    <dialog class="modal" :class="{ 'modal-open': showModal }">
      <form method="dialog" class="modal-box">
        <h3 class="font-bold text-lg pb-3">Create Sub-project</h3>
        <hr />
        <div class="items-center py-3">
          <div class="flex pt-4">
            <p class="py-4 pr-5">Sub-project Name：</p>
            <input type="text" placeholder="project name" class="px-5 input input-bordered max-w-xs"
              v-model="project_name" />
          </div>
          <div class="flex">
            <div class="flex items-center py-5 w-full">
              <p class="py-5 pr-5">Aerobox： </p>
              <Multiselect v-model="aerobox" :options="all_aerobox" mode="multiple" :searchable="true" :object="true" />
            </div>
          </div>

        </div>

        <div class="modal-action">
          <button class="btn" @click="toggleModal">返回</button>
          <button class="btn btn-primary" @click="CreateSubProject">新增</button>
        </div>
      </form>
    </dialog>

    <!-- 顯示邀請碼 -->
    <dialog class="modal" :class="{ 'modal-open': showCodeModal }">
      <form method="dialog" class="modal-box">
        <h3 class="font-bold text-lg pb-3">Invitation_code</h3>
        <hr />
        <p class="py-2 mt-5">
          The Invitation_code for {{ dropdown_project.name }} is
          <span class="text-red-600 text-bold">{{ dropdown_project.invitation_code }}</span>
        </p>
        <div class="modal-action">
          <button class="btn" @click="showCode">Return</button>
        </div>
      </form>
      <form method="dialog" class="modal-backdrop">
        <button @click="showCode">close</button>
      </form>
    </dialog>


    <!-- 確認刪除專案 -->
    <dialog class="modal" :class="{ 'modal-open': showDeleteModal }">
      <form method="dialog" class="modal-box">
        <h3 class="font-bold text-lg pb-3">Delete Project</h3>
        <hr />
        <p class="py-2 mt-5">
          Are you sure to delete <span class="text-red-600 text-bold"> {{ dropdown_project.name }} </span> ?
        </p>
        <div class="modal-action">
          <button class="btn" @click="deleteProject">Cancel</button>
          <button class="btn bg-red-600 text-white" @click="confirmDelete(dropdown_project.id)">Delete</button>
        </div>
      </form>
      <form method="dialog" class="modal-backdrop">
        <button @click="deleteProject">close</button>
      </form>
    </dialog>


  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import Multiselect from '@vueform/multiselect'
import { useRouter } from 'vue-router';
import axios from 'axios';
import draggable from "vuedraggable";

const router = useRouter();

const showModal = ref(false);
const showCodeModal = ref(false);
const showDeleteModal = ref(false);

const project_id = ref(0)  //create sub project
const project_name = ref('') //create sub project
const all_project = ref([]); // list
const main_project = ref([0])

const dropdown_project = ref({})

const id = localStorage.getItem('id')

const token = localStorage.getItem('token')

const all_aerobox = ref([])
const aerobox = ref([])


// new
// watch(main_project, (newVal, oldVal) => {
//       // 在message变化时执行的操作
//       console.log(main_project)
//       console.log(main_project.value)
//       console.log(`message 从 ${oldVal} 变为 ${newVal}`);
//     });


// 子專案換位子 用迴圈更新那個主專案底下的就好
const onSubProjectChange = (main) => {
  console.log(main_project.value)
  console.log(main)
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/dash/draggle`, { project: main, is_mainProject: false }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  }).then(data => console.log(data))
    .catch(err => console.log(err))
}

// 主專案換位子 main_poject.map
const onProjectChange = () => {
  console.log(main_project.value)
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/dash/draggle`, { project: main_project.value, is_mainProject: true }, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  }).then(data => console.log(data))
    .catch(err => console.log(err))
}

onMounted(() => {

  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/dash`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {

      console.log(data.data.data)
      main_project.value = data.data.data.filter(each => each.project.parent_id === null)

      const sub_project = data.data.data.filter((project) => {
        // 找到沒有主專案的子專案
        if (project.project.parent_id !== null) {
          return !main_project.value.some((mainProj) => mainProj.project.id === project.project.parent_id);
        }
      });

      // 合併專案 避免子專案不顯示
      main_project.value = [...main_project.value, ...sub_project]

      // 排序
      main_project.value.sort((a, b) => a.project.UserToProject[0].projectOrder - b.project.UserToProject[0].projectOrder);


      all_project.value = data.data.data
    }).catch(err => {
      if (err.response && err.response.status === 401) {
        console.log(err.response)
        window.location.assign('/signin');
      } else if (err.message === 'Network Error') {
        console.log(err)
      }
      else if (err.response.data.message === 'aerobox') {
        alert('Please ensure connect to the Internet')
      }
    })
})


function dropdownBtn(project) {
  dropdown_project.value = project
}

function showCode() {
  showCodeModal.value = !showCodeModal.value
}

function deleteProject() {
  showDeleteModal.value = !showDeleteModal.value
}

function confirmDelete(p_id) {
  axios.put(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}delete/project/${p_id}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      alert('Delete Success !')
      window.location.reload();
    }).catch(err => {
      console.log(err)
      alert('Delete Fail!')
      window.location.reload();
    })
}

function toggleModal(projectId) {
  console.log(projectId)
  project_id.value = projectId
  showModal.value = !showModal.value;

  // list aerobox in project
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}aerobox/sub/${projectId}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      const idArray = data.data.map(item => item.aerobox_id);
      all_aerobox.value = idArray
    }).catch(err => console.log(err))
}

function CreateSubProject() {
  if (project_name.value && aerobox.value.length > 0) {

    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/sub/create/${project_id.value}`, { name: project_name.value, project_id: project_id.value }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
      .then(data => {
        const aerobox_list = aerobox.value.map(each => each.value)
        axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}aerobox/bulk/${data.data.project_id}`, { aerobox: aerobox_list })
          .then(result => {
            alert('Create Success !')
            showModal.value = !showModal.value;
            project_name.value = '';
            aerobox.value = []
            window.location.reload();
          }).catch(err => console.log(err))
      }).catch(err => {
        alert('Error, Please try again!')
        console.log(err)
      })
  } else {
    alert('Project and Aerobox can not be null!!!')
  }

}
</script>

<style src="@vueform/multiselect/themes/default.css" scoped>
.btn-transparent {
  background-color: transparent !important;
  color: rgb(175, 12, 12);
  /* You can customize the text color as well */
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>