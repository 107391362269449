<template>
  <div class="navbar bg-base-100 h-14 px-5 shadow">

    <!-- user view -->
    <div class="flex-1">
      <button class="btn btn-ghost normal-case text-xl" @click="OpenSidebar">
        <svg v-if="isAdmin" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
        Aerobox Service
      </button>
    </div>

    <!-- admin view -->
    <div class="drawer z-10">
      <input id="my-drawer" type="checkbox" class="drawer-toggle" v-model="isOpen" />

      <div class="drawer-side">
        <label for="my-drawer" class="drawer-overlay"></label>
        <ul class="menu p-4 w-80 h-full bg-base-200 text-base-content">
          <li><a href="/">Dashboard</a></li>
          <li><a href="/project/manage">Project</a></li>
          <li><a href="/aerobox/manage">Aerobox</a></li>
          <li><a href="/user/manage">User</a></li>
          <li><a href="/apply/manage">Manage</a></li>
        </ul>
      </div>
    </div>




    <!--登入後-->
    <div v-if="isLoggedIn" class="flex-none">

      <!--提示按鈕-->
      <div class="dropdown dropdown-end">
        <label tabindex="0" class="btn btn-ghost btn-circle" @click="handleReadNotify">
          <div class="indicator">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
            <span class="badge badge-sm indicator-item">{{ new_notify }}</span>
          </div>
        </label>
        <div tabindex="0" class="dropdown-content z-[1] card card-compact w-80 p-2 shadow  bg-base-100 ">
          <div class="card-body h-72 scrollable overflow-y-auto">
            <div v-for="each in notify" class="">
              <div class="indicator w-full">
                <span v-if="!each.is_read" class="indicator-item indicator-middle badge badge-info"></span>
                <div class="grid w-full h-auto text-left">
                  <p class="font-bold text-lg">{{ each.event }}</p>
                  <p>{{ each.content }} - {{ CountDate(each.updatedAt) }} </p>
                  <div class="divider"></div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>

      <!--個人檔案-->
      <div class="dropdown dropdown-end px-4">
        <label tabindex="0" class="btn btn-ghost btn-circle avatar">
          <div class="w-8 rounded-full">
            <img :src="encodeURI(photo)" />
          </div>
        </label>
        <div tabindex="0" class="dropdown-content z-[1] card card-compact w-80 p-2 shadow  bg-base-100 ">
          <div class="card-body h-52">
            <div class="flex">
              <div class="rounded-full px-2">
                <label tabindex="0" class="btn btn-ghost btn-circle avatar">
                  <div class="w-32 rounded-full">
                    <img :src="encodeURI(photo)" />
                  </div>
                </label>
              </div>
              <div class="px-2">
                <p class="text-center">{{ username }}</p>
                <p class="text-center">{{ email }}</p>
                <button class="btn btn-ghost my-3" @click="router.push('/manageaccount')">Manege account</button>
              </div>
            </div>
            <div>
              <button class="btn w-full" @click="logout">Logout</button>
            </div>
          </div>
        </div>


      </div>
    </div>

    <div v-else class="flex-none">
      <ul class="menu menu-horizontal px-1">
        <li><a href="/signup">sign up </a></li>
        <li><a href="/signin">sign in </a></li>
      </ul>
    </div>

  </div>
</template>
  

<script setup>
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import axios from 'axios';
import moment from 'moment';
import user_photo from '../assets/User_font.png'

const router = useRouter()

const isAdmin = ref(false)
const isLoggedIn = ref(false);
const isOpen = ref(false)


const notify = ref([])
const new_notify = ref(0)


const username = ref('')
const email = ref('')
const photo = ref(user_photo)

const id = localStorage.getItem('id')

function OpenSidebar() {
  if (isAdmin.value) {
    isOpen.value = true
  } else {
    router.push('/')
  }
}

onMounted(() => {
  const token = localStorage.getItem('token');

  if (token) {
    
    isLoggedIn.value = true;

    const data = JSON.parse(atob(token.split('.')[1])); // 解码载荷部分并将其转换为JSON对象
    isAdmin.value = data.payload.user_admin
    username.value = data.payload.user_name
    email.value = data.payload.user_email
    photo.value = data.payload.user_photo ? process.env.VUE_APP_PHOTO_IDEASKY_SERVICE_URL + data.payload.user_photo : user_photo
    // 通知欄位 
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}list/notify`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
      .then(data => {
        const array = data.data.filter(item => (item.is_read === false))
        new_notify.value = array.length
        notify.value = data.data
      }).catch(err => {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem('token')
          window.location.assign('/signin');
        } else {
          console.log(err.response)
        }
      })

  }

});


function CountDate(date) {
  const updatedAt = moment(date); // 将更新时间转换为 moment 对象
  const currentTime = moment(); // 获取当前时间的 moment 对象
  const intervalInMinutes = currentTime.diff(updatedAt, 'minutes'); // 计算时间差

  if (intervalInMinutes === 0) {
    return 'just now'
  } else if (intervalInMinutes < 60) {
    return `${intervalInMinutes} minutes ago`
  } else if (intervalInMinutes < 60 * 24) {
    return `${parseInt(intervalInMinutes / 60)} hours ago`
  } else if (intervalInMinutes < 60 * 24 * 30) {
    return `${parseInt(intervalInMinutes / (60 * 24))} days ago`
  } else if (intervalInMinutes < 60 * 24 * 30 * 12) {
    return `${parseInt(intervalInMinutes / (60 * 24 * 30))} months ago`
  } else {
    return `${parseInt(intervalInMinutes / (60 * 24 * 30 * 12))} years ago`
  }
}

function handleReadNotify() {

  if (new_notify.value !== 0) {
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}notify/read`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
      .then(data => {
        new_notify.value = 0
      })
      .catch(err => console.log(err))
  } else {
    new_notify.value = 0
  }
}


function logout() {
  localStorage.removeItem('token')
  window.location.assign('/signin');
}

</script>