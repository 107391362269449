<template>
  <div class="px-10">
    <div class="text-2xl font-bold px-10 py-5">
      Aerobox
    </div>
    <div class="px-10 flex items-center pb-5">
      <div class="text-md px-2">Status : </div>
      <select class="select select-bordered me-10" v-model="select_status">
        <option value="0">All</option>
        <option value="1">Not in project</option>
        <option value="2">Online</option>
        <option value="3">Offline</option>
      </select>

      <div class="text-md px-2 ps-10">Project : </div>
      <select class="select select-bordered" v-model="select_project">
        <option value="0">All</option>
        <option v-for="project in all_project" :value="project.id">{{ project.name }}</option>
      </select>

      <button class="btn ms-10" @click="searchAerobox">Select</button>
    </div>

    <div class="px-10">
      <AeroboxTable :all_aerobox="show_aerobox" />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

import AeroboxTable from '@/components/AeroboxManage/AeroboxTable.vue'


const select_project = ref(0)
const select_status = ref('0')
const all_project = ref([])


const all_aerobox = ref([])
const show_aerobox = ref([])



onMounted(() => {
  //select project list
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/list`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      all_project.value = data.data.data
    }).catch(err => {
      if (err.response && err.response.status === 401) {
        console.log(err.response)
        window.location.assign('/signin');
      } else {
        console.log(err.response)
      }
    })


  // aerobox table  
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}aerobox`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      console.log(data.data)
      axios.get(`${process.env.VUE_APP_API_AEROBOX_SERVICE_URL}metadata`)
        .then(metadata => {
          console.log(metadata.data)
            show_aerobox.value = data.data.payload.map(each => {
            const site = metadata.data.data.find(one => one.aerobox_id === each.id)
            if (site) {
              return {
                data: each,
                site: site
              }
            } else {
              return {
                data: each,
                site: null
              }
            }
          })
          all_aerobox.value = show_aerobox.value
        })
    }).catch(err => {
      if (err.response && err.response.status === 401) {
        console.log(err.response)
        window.location.assign('/signin');
      } else {
        console.log(err.response)
      }
    })
})


function searchAerobox() {
  if (select_project.value === "0") {
    if (select_status.value === '0') {
      show_aerobox.value = all_aerobox.value
    } else if (select_status.value === '1') {
      show_aerobox.value = all_aerobox.value.filter(each => each.data.project === 0)
    } else if (select_status.value === '2') {
      show_aerobox.value = all_aerobox.value.filter(each => each.data.status === 'online')
    } else if (select_status.value === '3') {
      show_aerobox.value = all_aerobox.value.filter(each => each.data.status === 'offline')
    }
  } else {
    // 取得對應專案內的aerobox_id
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}list/aerobox/project`, { project_id: select_project.value }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    }).then(data => {
      const foundProjects = all_aerobox.value.filter((project) => {
        return data.data.aerobox.some((item) => item.aerobox_id === project.data.id);
      })
      show_aerobox.value = foundProjects

      if (select_status.value === '1') {
        show_aerobox.value = show_aerobox.value.filter(each => each.data.project === 0)
      } else if (select_status.value === '2') {
        show_aerobox.value = show_aerobox.value.filter(each => each.data.status === 'online')
      } else if (select_status.value === '3') {
        show_aerobox.value = show_aerobox.value.filter(each => each.data.status === 'offline')
      }
    }).catch(err => console.log(err))
  }
}


</script>