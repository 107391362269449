<template>
  <div class="container py-5 w-full">
    <div class="text-2xl pl-10 font-bold ">
      Project
    </div>
    <div class="">
      <Join />
      <ProjectList /> 
    </div>
</div>
</template>

<script setup>
import ProjectList from '@/components/Dashboard/ProjectList.vue';
import Join from '@/components/Dashboard/Join.vue';
import { onMounted } from 'vue';

onMounted(()=>{

  if(!localStorage.getItem('token')){
    window.location.assign('/signin');
  }

})

</script>
<style scoped>
.btn-transparent {
  background-color: transparent;
  color: rgb(175, 12, 12); /* You can customize the text color as well */
}
</style>