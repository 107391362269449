<template>
  <div class="px-10">
    <div class="flex justify-between">
      <div class="text-2xl font-bold px-10 py-5">
        Project
      </div>
      <div class="py-5">
        <button class="btn mx-4" @click="toggleModal">Create a project</button>
      </div>
    </div>

    <!-- <div class="text-md px-2">Project : </div>
      <select class="select select-bordered ">
        <option>Indoor</option>
        <option>Shelter</option>
        <option>Outdoor</option>
      </select>
      <button class="btn mb-3 mx-4">Select</button> -->

    <div class="px-10">
      <table class="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="border-b border-t text-base">
          <tr>
            <th class="pt-2 pb-2">Index</th>
            <th class="pt-2 pb-2">Project Name</th>
            <th class="pt-2 pb-2">Online</th>
            <th class="pt-2 pb-2">Offline</th>
            <th class="pt-2 pb-2">CreateAt</th>
            <th class="pt-2 pb-2">User</th>
            <th class="pt-2 pb-2">Owner</th>
            <th class="pt-2 pb-2">Log</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="11">
              <div class="flex justify-center pt-20 w-full">
                <span class="loading loading-spinner loading-lg "></span>
              </div>
            </td>
          </tr>
          <tr v-if="!isLoading && all_project.length===0">
            <td colspan="11">
              <div class="flex justify-center pt-20 w-full text-2xl">
                - You don't have any project now! - 
              </div>
            </td>
          </tr>
          <tr v-for="(project, index) in all_project" class="bg-white border-b text-base p-1">
            <td>{{ index + 1 }}</td>
            <td>{{ project.project.name }}</td>
            <td>
              <div class="flex justify-start items-center">
                <p class="pr-2 pt-1">{{ project.online }} </p>
                <p class="bg-green-300 w-5 h-5"></p>
              </div>
            </td>
            <td>
              <div class="flex justify-start items-center">
                <p class="pr-2 pt-1">{{ project.offline }} </p>
                <p class="bg-gray-300 w-5 h-5"></p>
              </div>
            </td>
            <td class="text-sm text-gray-400">{{ project.project.createdAt.slice(0, 10) }}</td>
            <td>{{ project.project.UserToProject.length }}</td>
            <td>{{ project.project.UserCreateProject.username }}</td>
            <td>
              <button class="
                mr-6 px-2 p-1 my-2 text-sm text-blue-400 
                rounded-lg border border-slate-400 border-blue-400
                hover:text-white
                hover:bg-blue-400
                hover:border-transparent" @click="toggleLogModal(project.project.id)">
                Log
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- log modal -->
      <dialog class="modal" :class="{ 'modal-open': showLogModal }">
        <form method="dialog" class="modal-box">
          <h3 class="font-bold text-lg pb-3">Project Log</h3>

          <div v-for="log in project_log" class="items-center py-3">
            <p class="py-1 pr-5">User : {{ log.ProjectToUser.username }}</p>
            <p class="py-1 pr-5">Type : {{ log.type_joined }}</p>
            <p class="py-1 pr-5">Date : {{ log.updatedAt.slice(0, 10) }}</p>
            <hr />
          </div>

          <div class="modal-action">
            <button class="btn" @click="toggleLogModal(0)">Close</button>
          </div>
        </form>
      </dialog>


      <dialog class="modal" :class="{ 'modal-open': showCreateModal }">
        <form method="dialog" class="modal-box">
          <h3 class="font-bold text-lg pb-3">Create Project</h3>
          <hr />
          <div class="items-center py-3">
            <div class="flex">
              <p class="py-4 pr-5">Project Name：</p>
              <input type="text" placeholder="project name" class="px-5 input input-bordered max-w-xs"
                v-model="project_name" />
            </div>
            <div class="flex">
              <div class="flex items-center py-5 w-full">
                <p class="py-5 pr-5">Aerobox： </p>
                <Multiselect v-model="aerobox" :options="all_aerobox" mode="multiple" :close-on-select="false"
                  :searchable="true" :object="true" />
              </div>
            </div>

          </div>

          <div class="modal-action">
            <button class="btn" @click="toggleModal">Return</button>
            <button class="btn btn-primary" @click="createProject">Create</button>
          </div>
        </form>
      </dialog>

    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import Multiselect from '@vueform/multiselect'
import axios from 'axios';

const showCreateModal = ref(false);
const showLogModal = ref(false);

const isLoading = ref(true)

// careat project
const aerobox = ref([])
const all_aerobox = ref([])
const project_name = ref('')

const all_project = ref([])

const project_log = ref([])

onMounted(() => {
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/manage`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      console.log(data.data)
      all_project.value = data.data.data
      isLoading.value= false
    }).catch(err => {
      if (err.response && err.response.status === 401) {
        console.log(err.response)
        window.location.assign('/signin');
      } else {
        console.log(err.response)
      }
    })
})


function createProject() {
  if (project_name.value && aerobox.value.length > 0) {
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project`, { name: project_name.value }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
      .then(data => {
        const aerobox_list = aerobox.value.map(each => each.value)
        console.log(data.data)
        axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}aerobox/bulk/${data.data.project_id}`, { aerobox: aerobox_list })
          .then(result => {
            alert('Create Success !')
            showCreateModal.value = !showCreateModal.value;
            project_name.value = '';
            aerobox.value = []
            window.location.reload();
          })
      }).catch(err => {
        alert('Error, Please try again!')
        console.log(err)
      })
  } else {
    alert('Project and Aerobox can not be null!!!')
  }
}

function toggleModal() {
  showCreateModal.value = !showCreateModal.value;
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}aerobox`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(data => {
      const idArray = data.data.payload.map(item => item.id);
      all_aerobox.value = idArray
    }).catch(err => console.log(err))
}



function toggleLogModal(id) {
  showLogModal.value = !showLogModal.value;

  if (id !== 0) {
    axios.get(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/log/${id}`)
      .then(data => {
        project_log.value = data.data.data
      }).catch(err => console.log(err))
  }
}

</script>
<style src="@vueform/multiselect/themes/default.css"></style>