<template>
  <div class="container px-10 pt-5  z-10">
    <a href="/"> &lt Dashboard</a>
    <div class="md:flex py-5 container">
      <div class="md:w-1/2 sm:w-full">
        <div class="avatar flex justify-center ">
          <input ref="fileInput" type="file" style="display: none;" class="file-input w-full max-w-xs" @change="photoTest" accept=".png, .jpg, .jpeg"/>
          <div class="w-52 rounded-xl" style="cursor: pointer;">
            <img :src="previewImage? previewImage : encodeURI(photo)" alt="photo" @click="openFileInput" />
          </div>
        </div>
        <div v-if="uploadButton" class="flex justify-center mt-4">
            <button @click="cancelSave" class="py-1 px-2 mx-5 bg-gray-500 text-white rounded-lg">Return</button>
              <button @click="uploadAvatar" class="py-1 px-2 bg-green-500 text-white rounded-lg">Save</button>
          </div>
        <div class="flex justify-center grid pt-5">
          <div class="text-2xl bold">Profile</div>
          <div class="px-4 py-2 text-lg">
            <p class="py-1">First Name: <span class="py-1 px-2" v-if="!isEditing">{{ profile.first_name }}</span><input v-else
                v-model="first_name" class="py-1 px-2 border"></p>
            <p class="py-1">Last Name: <span class="py-1 px-2" v-if="!isEditing">{{ profile.last_name }}</span><input v-else
                v-model="last_name" class="py-1 px-2 border"></p>
            <p class="py-1">Username: <span class="py-1 px-2" v-if="!isEditing">{{ profile.username }}</span><input v-else
                v-model="username" class="py-1 px-2 border"></p>
            <p class="py-1">Email: <span class="py-1 px-2" v-if="!isEditing">{{ profile.email }}</span><input v-else v-model="email"
                class="py-1 px-2 border"></p>
            <p class="py-1">Department:
              <span class="py-1 px-2" v-if="!isEditing">{{ department }}</span>
              <select v-else class="select select-bordered w-full max-w-xs my-2" v-model="department_id">
                <option v-for="each in all_department" :key="each.id" :value="each.id">{{ each.department }}</option>
              </select>
            </p>
            <p class="py-1">Nation:
              <span class="py-1 px-2" v-if="!isEditing">{{ nation }}</span>
              <select v-else class="select select-bordered w-full max-w-xs my-2" v-model="nation_id">
                <option v-for="each in all_nation" :key="each.id" :value="each.id">{{ each.nation }}</option>
              </select>
            </p>

            <div v-if="!isEditing" class="flex justify-end">
              <button @click="startEditing" class="py-1 px-2 bg-blue-500 text-white rounded-lg">Edit</button>
            </div>
            <div v-else class="flex justify-end">
              <button @click="cancelSave" class="py-1 px-2 mx-5 bg-gray-500 text-white rounded-lg">Return</button>
              <button @click="saveChanges" class="py-1 px-2 bg-green-500 text-white rounded-lg">Save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="md:w-1/2 sm:w-full">
        <div>
          <div class="text-2xl bold py-5">Apply Project</div>
          <div class="flex">
            <select class="select w-full max-w-xs select-bordered " v-model="project_id">
              <option value="0" disabled>Project</option>
              <option v-for="each in apply_project" :value="each.id">{{ each.name }}</option>
            </select>
            <button class="btn ml-10" @click="applyProject">apply</button>
          </div>
        </div>
        <div>
          <div class="text-2xl bold py-5 pt-10">Pending Project</div>
          <div class="col h-96 scrollable overflow-y-auto">
            <div v-for="each in all_project" class="card card-side bg-base-100 shadow-xl md:w-96 xs:w-68 mb-5 m-2">
              <div class="card-body p-5">
                <h2 class="card-title">{{ each.UserToProject.name }}</h2>
                <p class="text-end text-gray">{{ each.updatedAt.slice(0, 10) }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import user_photo from '../assets/User_font.png'
const profile = ref({})
const nation = ref('')
const department = ref('')
const all_project = ref([])
const apply_project = ref([]);
const project_id = ref(0)


const isEditing = ref(false)

const email = ref('');
const last_name = ref('');
const first_name = ref('');
const username = ref('');
const department_id = ref(0)
const nation_id = ref(0)
const all_department = ref([])
const all_nation = ref([])

const fileInput = ref()

const photo = ref()
const origin_photo = ref()
const previewImage = ref(null)
const uploadButton = ref(false)

function openFileInput(){
  fileInput.value.click()
}

function photoTest(e){
  const file = e.target.files[0];
      if (file) {
        uploadButton.value = true
        photo.value = file
        // 使用FileReader读取图像文件并生成Base64编码数据
        const reader = new FileReader();
        reader.onload = () => {
          previewImage.value = reader.result; // 将Base64编码数据设置为预览图像的src
        };
        reader.readAsDataURL(file);
      }
    }

function uploadAvatar(){
  const formData = new FormData(); // 创建FormData对象

      // 添加文件到FormData对象中，'file'是后端接收文件的字段名
      formData.append('photo', photo.value);

      axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}user/avatar/upload`, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data', // 设置请求头
        },
      })
    .then(async data => {
      localStorage.setItem('token', data.data.token)
      photo.value = `${process.env.VUE_APP_PHOTO_IDEASKY_SERVICE_URL+data.data.data.photo}`
      uploadButton.value = false
      await alert('upload success');
      window.location.reload();
    })
    .catch(err => console.log(err))
}


const startEditing = () => {
  // 进入编辑模式
  isEditing.value = true;

  first_name.value = profile.value.first_name
  last_name.value = profile.value.last_name
  username.value = profile.value.username
  email.value = profile.value.email

  axios.get(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}department`)
    .then(data => {
      all_department.value = data.data.data
    })
    .catch(err => console.log(err))

  axios.get(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}nation`)
    .then(data => {
      all_nation.value = data.data.data
    })
    .catch(err => console.log(err))
}

const cancelSave = () => {
  previewImage.value= null
  photo.value = origin_photo.value
  uploadButton.value = false
  isEditing.value = false
}

const saveChanges = () => {
  axios.put(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}user`, { last_name: last_name.value, first_name: first_name.value, email: email.value, username: username.value, department_id: department_id.value, nation_id: nation_id.value },{ headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
      'Content-Type': 'application/json',
    }})
    .then(data => {
      console.log(data)
      alert('Save success')
      window.location.reload();
    }).catch(err => {
      alert('Save fail! Please try again')
      console.log(err)
    })
  isEditing.value = false
}

onMounted(() => {
  const token = localStorage.getItem('token')
  if (token) {
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}user/profile/data`, {
    headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    }
  })
      .then(data => {
        console.log(data)
        nation_id.value = data.data.UserNation.id
        nation.value = data.data.UserNation.nation
        department.value = data.data.UserDepartment.department
        department_id.value = data.data.UserDepartment.id
        profile.value = data.data
        origin_photo.value = data.data.UserPhoto ? process.env.VUE_APP_PHOTO_IDEASKY_SERVICE_URL+data.data.UserPhoto.photo : user_photo
        photo.value = data.data.UserPhoto ? process.env.VUE_APP_PHOTO_IDEASKY_SERVICE_URL+data.data.UserPhoto.photo : user_photo
        all_project.value = data.data.ProjectToUser
      }).catch(err => {
      if (err.response && err.response.status === 401) {
        window.location.assign('/signin');
      } else {
        console.log(err)
      }
    })

    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}user/profile/project`, {  headers: {
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json',
    } })
      .then(data => {
        apply_project.value = data.data.data
      }).catch(err => {
        console.log(err.response)
    })
  }
});

function applyProject() {

  if (project_id.value !== '0') {
    axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}project/apply`, { project_id: project_id.value },{ headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`, 
      'Content-Type': 'application/json',
    }})
      .then(data => {
        alert('Apply Success')
        window.location.reload();
      }).catch(err => {
        console.log('Apply Fail', err)
        alert('Apply Fail. Please Try Again')
      })
  } else {
    alert('Please Select A Project')
  }
}

</script>