<template>
  <div class="backgroundStyle flex h-screen justify-start items-center px-10">
    <div class="relativeitems-center  overflow-hidden px-10">
      <div class="w-full p-6 m-auto bg-white bg-opacity-30 rounded-md shadow-md lg:max-w-lg">
          <h1 class="text-3xl font-semibold text-center pb-5">Sign Up</h1>
          <div class="space-y-4">
              <div>
                  <input type="text" placeholder="Username" class="w-full input my-2" v-model="username" />
                  <input type="text" placeholder="First Name" class="w-full input my-2" v-model="first_name" />
                  <input type="text" placeholder="Last Name" class="w-full input my-2" v-model="last_name" />
                  <input type="text" placeholder="Email Address" class="w-full input my-2" v-model="email" />
                  
                  <select class="select select-bordered w-full max-w-xs my-2" v-model="department_id">
                    <option disabled value="0">Affiliation</option>
                    <option
                      v-for="each in department"
                      :key="each.id"
                      :value="each.id"
                    >{{ each.department }}</option>
                  </select>
                  <select class="select select-bordered w-full max-w-xs my-2" v-model="nation_id">
                    <option disabled value="0">Nation</option>
                    <option
                      v-for="each in nation"
                      :key="each.id"
                      :value="each.id"
                    >{{ each.nation }}</option>
                  </select>
                  <input type="password" placeholder="Enter Password" class="w-full input my-2" v-model="password" />
                  <input type="password" placeholder="Confirm Password" class="w-full input my-5" v-model="confirmPassword" />
              </div>
              <div>
                  <button class="btn btn-primary" @click="signup()">Sign Up</button>
              </div>
            </div>
      </div>
  </div>
  </div>
</template>
<script setup>
import { ref,onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter();

const email = ref('');
const password = ref('');
const confirmPassword = ref('')
const last_name = ref('');
const first_name = ref('');
const username = ref('');
const department_id = ref(0);
const nation_id = ref(0)
const department = ref([])
const nation = ref([])

axios.get(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}department`)
.then(data => {
  department.value = data.data.data
})
.catch(err => console.log(err))

axios.get(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}nation`)
.then(data => {
  nation.value = data.data.data
})
.catch(err => console.log(err))

onMounted(() => {
  const token = localStorage.getItem('token')
  if (token) {
    window.location.assign('/');
  }
})

const signup = async() =>{
 if(password.value !== confirmPassword.value){
  alert('Password Different!');
 }else{
  axios.post(`${process.env.VUE_APP_API_IDEASKY_SERVICE_URL}user`, {username: username.value, first_name: first_name.value, last_name:last_name.value, password:password.value, email:email.value, department_id: department_id.value, nation_id:nation_id.value })
  .then(data =>{
      alert('Sign up Success')
      router.push('/signin')
  })
  .catch(err => {
    alert('Sign up Fail!')
    console.log(err)
  })
 }
}

</script>
<style scoped>
.backgroundStyle {
  background-image: url('../assets/banner.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
};
</style>